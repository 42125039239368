import axios from 'axios';

const getUIAssetsByOrgId = async (orgId, setError, setUIAssetsData) => {
    setError(null);
    setUIAssetsData([]); // Reset user personas data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching ui asset data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/ui-assets/ui-assets-by-org', {
        params: { orgId }
      });
      console.log('API response received1:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting ui assets data 1:', items);
      setUIAssetsData(items); // Correctly update the state with received data
      console.log('Updated ui asset data 1:', items);
    } catch (error) {
      console.error('Error fetching ui asset data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch ui asset 1 data');
    }
};

const getUIAssetById = async (id, setError, setUIAssetByIdData) => {
  setError(null);
  setUIAssetByIdData([]); // Reset user personas data to an empty array

  if (!id) {
    setError('id parameter is required');
    return;
  }

  try {
    console.log(`Fetching ui asset data for id: ${id}`);
    const response = await axios.get('/fxapi/ui-assets/ui-asset-by-id', {
      params: { id }
    });
    console.log('API response raw data: ');
    console.dir(response.data);
    console.log('test after data items');

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting ui asset by id:', items);
    setUIAssetByIdData(items); // Correctly update the state with received data
    console.log('Updated ui asset by id:', items);
  } catch (error) {
    console.error('Error fetching ui asset by id:', error);
    setError(error.response ? error.response.data : 'Failed to fetch ui asset by id');
  }
};

const getUIAssetsByProjectId = async (projectId, setError, setUIAssetsByProjectData) => {
  setError(null);
  setUIAssetsByProjectData([]); // Reset user personas data to an empty array

  if (!projectId) {
    setError('ProjectId parameter is required');
    return;
  }

  try {
    console.log(`Fetching ui asset data for projectId: ${projectId}`);
    const response = await axios.get('/fxapi/ui-assets/ui-assets-by-project', {
      params: { projectId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting ui assets by project data 1:', items);
    setUIAssetsByProjectData(items); // Correctly update the state with received data
    console.log('Updated ui assets by project data 1:', items);
  } catch (error) {
    console.error('Error fetching ui assets by project data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch ui assets by project 1 data');
  }
};

const getUIAssetsByAnchorId = async (anchorId, setError, setUIAssetsByAnchorData) => {
  setError(null);
  setUIAssetsByAnchorData([]); // Reset user personas data to an empty array

  if (!anchorId) {
    setError('AnchorId parameter is required');
    return;
  }

  try {
    console.log(`Fetching ui asset data for anchorId: ${anchorId}`);
    const response = await axios.get('/fxapi/ui-assets/ui-assets-by-anchor', {
      params: { anchorId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting ui assets by anchor data 1:', items);
    setUIAssetsByAnchorData(items); // Correctly update the state with received data
    console.log('Updated ui assets by anchor data 1:', items);
  } catch (error) {
    console.error('Error fetching ui assets by anchor data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch ui assets by anchor data');
  }
};

const createUIAsset = async (related_org, related_project, name, img_full_url, img_thumb_url, version, setUIAssetResponse) => {

  console.log('passed related_org: ' + related_org);
    console.log('passed related_project: ' + related_project);
    console.log('passed name: ' + name);
    console.log('passed image full url: ' + img_full_url);
    console.log('passed image thumb url: ' + img_thumb_url);
    console.log('passed version: ' + version);

  try {
    

    // Generate ui asset id
    const uiAssetId = generateUUID();

    // Simple validation
    if (!uiAssetId || !name ) {
      console.warn('Missing required parameters: uiAssetId, name.');
      return;
    }

    console.log(`Creating uiAsset for orgId: ${related_org}, uiAssetName: ${name}`);

    // Send request
    const response = await axios.post('/fxapi/ui-assets/create', {
      ui_asset_id: uiAssetId,
      related_org,
      related_project,
      name,
      img_full_url,
      img_thumb_url,
      version
    });

    console.log('API response received:', response.data);

    // Update state with response data
    setUIAssetResponse(response.data);

  } catch (error) {
    // Suppress the error, just log it quietly
    console.error('Error creating ui asset:', error);
    // Optionally, handle specific errors here without alerting the user
  }
};

const updateUIAsset  = async (uiAssetId, name, img_full_url, related_org, related_anchor) => {

  try {
    const response = await axios.put(`/fxapi/ui-assets/update/${uiAssetId}`, {
      ui_asset_id: uiAssetId,
      name: name,
      img_full_url: img_full_url,
      related_org: related_org,
      related_anchor: related_anchor
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

const deleteUIAsset = async (uiAssetId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!uiAssetId) {
    setError('uiAssetId parameter is required');
    return;
  }

  try {
    console.log(`Deleting ui asset with ID: ${uiAssetId}`);
    const response = await axios.delete(`/fxapi/ui-assets/delete/${uiAssetId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted ui asset with ID: ${uiAssetId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting ui asset:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete ui asset';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

// Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export { getUIAssetsByOrgId, getUIAssetsByProjectId, getUIAssetsByAnchorId, createUIAsset, getUIAssetById, deleteUIAsset, updateUIAsset };