import React, { useState, useEffect } from 'react';
import './CompQuickCreateStatus.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getViewObjectivesProjectsByOrgId } from '../fxAPI/fxObjectivesAPI.js';
import { getUsersByOrgId } from '../fxAPI/fxUsersAPI.js';
import { createStatus } from '../fxAPI/fxStatusAPI.js';

const CompQuickCreateStatus = (props) => {

  const [error, setError] = useState(null);
  const [viewObjectivesProjectsByOrg, setViewObjectivesProjectsByOrgData] = useState([]); // Initialize as an array
  const [newUserId, setNewUserId] = useState(''); // Initialize with empty string
  const [newObjectiveId, setNewObjectiveId] = useState(''); // Initialize with empty string
  const [newTitle, setNewTitle] = useState(''); // Initialize with empty string
  const [newDescription, setNewDescription] = useState(''); // Initialize with empty string
  const [statusResponse, setStatusResponse] = useState(null);

  const fxAPIFetchViewObjectivesProjectsByOrgId = async (orgId) => {
    await getViewObjectivesProjectsByOrgId(orgId, setError, setViewObjectivesProjectsByOrgData);
  };

  const [usersByOrgData, setUsersByOrgData] = useState([]); // Initialize as an array

  const fxAPIFetchUsersByOrgId = async (orgId) => {
    await getUsersByOrgId(orgId, setError, setUsersByOrgData);
  };

  const handleUserChange = (e) => {
    setNewUserId(e.target.value);
  };

  const handleObjectiveChange = (e) => {
    setNewObjectiveId(e.target.value);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setNewDescription(e.target.value);
  };

  const handleCreateStatus = (event) => {
    event.preventDefault();
    const related_component = newObjectiveId;
    const related_org = localStorage.getItem("fxLocalOrgID");
    const related_project = '';
    const user_assigned = newUserId;
    const requestor = localStorage.getItem("fxLocalUserID");
    const title = newTitle;
    const description = newDescription;
    const related_component_type = 'objective';

    
    createStatus(related_component, related_org, related_project, user_assigned, requestor, title, description, related_component_type, setStatusResponse);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    if (currentOrgId) {
      fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
      fxAPIFetchUsersByOrgId(currentOrgId);
    }
  }, []);

  return (
    <Box p={3} className="fx-">
      <form className='fx-comp-quick-create-status-form' onSubmit={handleCreateStatus}>
        <div>
          <FormControl fullWidth>
            <InputLabel id="status-from-label">Get Status From</InputLabel>
            <Select
              labelId="status-from-label"
              id="status-from-select"
              value={newUserId}
              label="Get Status From"
              onChange={handleUserChange}
            >
              {usersByOrgData.map((user) => (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.first_name} {user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <br />
        <div>
          <FormControl fullWidth>
            <InputLabel id="related-to-label">Related To</InputLabel>
            <Select
              labelId="related-to-label"
              id="related-to-select"
              value={newObjectiveId}
              label="Related To"
              onChange={handleObjectiveChange}
            >
              {viewObjectivesProjectsByOrg.map((viewObjective) => (
                <MenuItem key={viewObjective.objective_id} value={viewObjective.objective_id}>
                  {viewObjective.objective_view_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <br />
        <div>
          <TextField id="outlined-basic" label="Title" variant="outlined" onChange={handleTitleChange} value={newTitle} />
        </div>
        <br />
        <div className="fx-comp-quick-create-status-text-area-description">
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={4}
            value={newDescription}
            onChange={handleDescriptionChange}
            fullWidth
          />  
        </div>
        <br />
        <Button variant='outlined' type="submit">Create Status</Button>     
      </form>
    </Box>
  );
};

export default CompQuickCreateStatus;