import React, { useEffect, useState } from 'react';
import './CompCRUDModal.css'; // Create this CSS file for styling
import CompCRUDFormUserPersona from './CompCRUDFormUserPersona';
import CompCRUDFormTask from './CompCRUDFormTask';
import CompCRUDFormStatus from './CompCRUDFormStatus';
import CompCRUDFormUIAsset from './CompCRUDFormUIAsset';
import CompCRUDFormTeamMember from './CompCRUDFormTeamMember';
import CompCRUDFormObjective from './CompCRUDFormObjective';
import CompCRUDFormProject from './CompCRUDFormProject';
import CompCRUDFormSubjective from './CompCRUDFormSubjective.jsx';
import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';
import { deleteProject } from '../fxAPI/fxProjectsAPI.js';
import { deleteObjective } from '../fxAPI/fxObjectivesAPI.js';
import { deleteTask } from '../fxAPI/fxTasksAPI.js';
import { deleteStatus } from '../fxAPI/fxStatusAPI.js';
import { deleteUIAsset } from '../fxAPI/fxUIAssetsAPI.js';
import { deleteUserPersona } from '../fxAPI/fxUserPersonasAPI.js';
import { deleteUser } from '../fxAPI/fxUsersAPI.js';


const CompCRUDModal = ({ isOpen, onClose, children, modalTitle, modalComponentData, itemType, crudMethod, relatedComponentId }) => {
  const [error, setError] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState(null);

  if (!isOpen) return null; // Early return if modal is not open

  console.log('Component data on modal:');
  console.dir(modalComponentData);
  /*
  if (itemType === "project") {
    console.log('project_id: ' + modalComponentData.project_id); 
  }
  */

  const deleteObject = async (e, objectType, objectTypeData) => {
    e.preventDefault(); // Prevent default action (form submission, etc.)

    if (objectType === "project") {
      const projectId = objectTypeData.project_id;
      try {
        await deleteProject(projectId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete project'); // Handle error gracefully
      }
    }

    if (objectType === "objective") {
      const objectiveId = objectTypeData.objective_id;
      try {
        await deleteObjective(objectiveId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete objective'); // Handle error gracefully
      }
    }

    if (objectType === "task") {
      const taskId = objectTypeData.task_id;
      try {
        await deleteTask(taskId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete task'); // Handle error gracefully
      }
    }

    if (objectType === "status") {
      const statusId = objectTypeData.status_id;
      try {
        await deleteStatus(statusId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete status'); // Handle error gracefully
      }
    }

    if (objectType === "ui-asset") {
      const uiAssetId = objectTypeData.ui_asset_id;
      try {
        await deleteUIAsset(uiAssetId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete ui asset'); // Handle error gracefully
      }
    }

    if (objectType === "user-persona") {
      const userPersonaId = objectTypeData.user_persona_id;
      try {
        await deleteUserPersona(userPersonaId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete user persona'); // Handle error gracefully
      }
    }

    if (objectType === "team-member") {
      const userId = objectTypeData.user_id;
      try {
        await deleteUser(userId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete user'); // Handle error gracefully
      }
    }

  };



  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>

        <button className="modal-close" onClick={onClose}>X</button>

        <div>
          {error && (
            <div className="error">
              {error}
            </div>
          )}
          {deleteResponse && (
            <div className="delete-response">
              {deleteResponse}
            </div>
          )}
        </div>

        <h2>{modalTitle}</h2>
        {children}
        Component ID: {relatedComponentId}
        Item Type: {itemType} CRUD Method: {crudMethod}

        {/* User Persona Views */}
        { itemType === "user-persona" &&
          <CompCRUDFormUserPersona crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Task Views */}
        { itemType === "task" &&
          <CompCRUDFormTask crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Status Views */}
        { itemType === "status" &&
          <CompCRUDFormStatus crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* UI Asset Views */}
        { itemType === "ui-asset" &&
          <CompCRUDFormUIAsset crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Team Member Views */}
        { itemType === "team-member" &&
          <CompCRUDFormTeamMember crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Objective Views */}
        { itemType === "objective" &&
          <CompCRUDFormObjective crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Subjective Views */}
        { itemType === "subjective" &&
          <CompCRUDFormSubjective crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Project Views */}
        { itemType === "project" &&
          <CompCRUDFormProject crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {crudMethod === "delete" &&
            <div>
              {/*
                {error && <div className="error">{error}</div>}
                {deleteResponse && <div className="success">{deleteResponse.message}</div>}
              */}
              <Alert severity="info"><div className='fx-comp-crud-modal-delete-row'><div>Confirm delete?</div> <div><Button onClick={(e) => deleteObject(e, itemType, modalComponentData)}>Delete</Button></div></div></Alert>
            </div>
        }


      </div>
    </div>
  );
};

export default CompCRUDModal;