import React, { useState, useEffect } from 'react';
import './CompFeatureCard.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const CompFeatureCard = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);

  function handleToggleItem(toggleValue) {
    setIsExpanded(toggleValue);
    console.log('toggle: ' + toggleValue);
  }

  return (
    <>
    <div className="fx-comp-feature-card-outer">
        <div className="fx-comp-feature-card-inner">
            {/* Hiding temporarily
            <div>
                <span className="fx-comp-feature-card-number">{props.cardNumber}</span>
            </div>
            */}
            <div>
                <span className="fx-comp-feature-card-title">{props.cardTitle}</span>
            </div>
            <p>
                {props.cardDescription}
            </p>
            <div className='fx-comp-feature-card-content-inner'>
                {props.children}
                <img src={props.cardThumb} width="100%" height="auto" />
            </div>
            <div>
                <Button className="fx-comp-feature-card-button" size="small" href={props.cardLinkPath} variant="outlined">{props.cardLinkTitle}</Button>
                
            </div>
        </div>
    </div>
    {/* Hiding temporarily
    <div className='fx-comp-feature-card-status-header'>
            <>
            {!isExpanded &&
            <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(true)}><ExpandMoreIcon /></button>
            }
            {isExpanded &&
            <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(false)}><ExpandLessIcon /></button>
            }
            </>
            Status
    </div>
    */}
    {isExpanded && (
        <div>Status List</div>
    )}
    </>
  );
};

export default CompFeatureCard;