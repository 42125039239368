import axios from 'axios';

const getUsersByOrgId = async (orgId, setError, setUsersByOrgData) => {
    setError(null);
    setUsersByOrgData([]); // Reset user personas data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching user persona data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/users/users-by-org', {
        params: { orgId }
      });
      console.log('API response received1:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting users by org data 1:', items);
      setUsersByOrgData(items); // Correctly update the state with received data
      console.log('Updated users by org data 1:', items);
    } catch (error) {
      console.error('Error fetching users by org data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch users by org 1 data');
    }
};

const getUsersByProjectId = async (projectId, setError, setUsersByProjectData) => {
    setError(null);
    setUsersByProjectData([]); // Reset user personas data to an empty array

    if (!projectId) {
      setError('ProjectId parameter is required');
      return;
    }

    try {
      console.log(`Fetching users by project data for projectId: ${projectId}`);
      const response = await axios.get('/fxapi/users/users-by-project', {
        params: { projectId }
      });
      console.log('API response received1:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting user data 1:', items);
      setUsersByProjectData(items); // Correctly update the state with received data
      console.log('Updated user persona data 1:', items);
    } catch (error) {
      console.error('Error fetching user persona data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch user personas 1 data');
    }
};

const createUser = async (username, email_address, related_org, setUserResponse) => {
  try {
    // Generate userId
    const userId = generateUUID();

    // Simple validation
    if (!userId || !username || !email_address || !related_org) {
      console.warn('Missing required parameters: userId, username, email_address, or related_org.');
      return;
    }

    console.log(`Creating user for orgId: ${related_org}, username: ${username}`);

    // Send request
    const response = await axios.post('/fxapi/users/create', {
      user_id: userId,
      username,
      email_address,
      related_org,
    });

    console.log('API response received:', response.data);

    // Update state with response data
    setUserResponse(response.data);

  } catch (error) {
    // Suppress the error, just log it quietly
    console.error('Error creating project:', error);
    // Optionally, handle specific errors here without alerting the user
  }
};

const updateUser  = async (userId, username, email_address, related_org) => {

  try {
    const response = await axios.put(`/fxapi/users/update/${userId}`, {
      user_id: userId,
      username: username,
      email_address: email_address,
      related_org: related_org
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

const getUserById = async (userId, setError, setUserData) => {
  setError(null);
  setUserData([]);

  try {
    const response = await axios.get(`/fxapi/users/user-by-id`, {
      params: { userId },
    });
    setUserData(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user by ID:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const deleteUser = async (userId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!userId) {
    setError('userId parameter is required');
    return;
  }

  try {
    console.log(`Deleting user with ID: ${userId}`);
    const response = await axios.delete(`/fxapi/users/delete/${userId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted users with ID: ${userId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting user:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete user';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

// Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export { getUsersByOrgId, getUsersByProjectId, createUser, deleteUser, updateUser, getUserById };