import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageComponents.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getIntegrationsByOrgId } from '../fxAPI/fxIntegrationsAPI.js';


const PageComponents = (props) => {

  const [error, setError] = useState(null);

  /* INTEGRATIONS */
  const [integrationsByOrgId, setIntegrationsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchIntegrationsByOrgId = async (orgId) => {
    await getIntegrationsByOrgId(orgId, setError, setIntegrationsByOrgData);
    console.log('all integrations by org Id');
    console.dir(integrationsByOrgId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchIntegrationsByOrgId(currentOrgId);
    
  }, []);

  return (
    <CompPageTempMaster3Panel headingOne="Components">

      <CompContentBlock headingThree="Component Gallery">
        Components...
        {integrationsByOrgId.map((integrationItem) => (
          <div>{integrationItem.integration_id}</div>
        ))}
      </CompContentBlock>
      
      {/*
      <CompContentBlock headingThree="Integration 1: ...">
        Integrations...
        {integrationsByOrgId.map((integrationItem) => (
          <div>{integrationItem.integration_id}</div>
        ))}
      </CompContentBlock>
      */}

      
    </CompPageTempMaster3Panel>
  );
}

export default PageComponents;