import React, { useState, useEffect } from 'react';
import './CompMiniAvatar.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { getUserById } from '../fxAPI/fxUsersAPI.js';


const CompMiniAvatar = (props) => {

  const [error, setError] = useState(null);

  const [abbInitials, setAbbInitials] = useState(null);

  const { userId} = props;

  const [userData, setUserData] = useState([]); // Initialize as an array

  const fxAPIFetchUserById = async (currentUserId) => {
    return await getUserById(currentUserId, setError, setUserData);
  };

  const firstName = 'Test';
  const lastName = 'Test';

  // Function to get initials
  const getInitials = (name) => {
    const names = name.split(' ');
    return names.map(n => n[0].toUpperCase()).join('');
  };

  // Get initials for first name and last name
  const initials = `${getInitials(firstName)}${getInitials(lastName)}`;

  useEffect(() => {
    console.log('Updated userData for Avatar:', userData);
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        const userData = await fxAPIFetchUserById(userId);
        console.log('Fetched userData:', userData);
        console.log('Item: ' + userData.items[0].first_name);
        
        // Rest of your logic that depends on userData
        if (userData) {
          // Do something with userData
          const initials = `${getInitials(userData.items[0].first_name)}${getInitials(userData.items[0].last_name)}`;
          console.log('User initials:', initials);
          setAbbInitials(initials);
        }
      }
    };
  
    fetchData();
  }, [userId]);

  return (
    <div className='fx-comp-mini-avatar-wrap'>
      <Avatar>{abbInitials}</Avatar> {/*{userId}*/}
    </div>
  );
};

export default CompMiniAvatar;