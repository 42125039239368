import React from 'react';
import './CompCRUDModalActionBar.css';


const CompCRUDModalActionBar = (props) => {

  return (
    <div className='fx-comp-crud-modal-action-bar-container'>
      {props.children}
    </div>
  );
};

export default CompCRUDModalActionBar;