import axios from 'axios';

const getSubjectivesObjectivesByOrgId = async (orgId, setError, setSubjectivesData) => {
    setError(null);
    setSubjectivesData([]); // Reset subjectives data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching subjective data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/subjectives/subjectives-objectives-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting subjectives data:', items);
      setSubjectivesData(items); // Correctly update the state with received data
      console.log('Updated subjective data:', items);
    } catch (error) {
      console.error('Error fetching subjective data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch subjectives data');
    }
};

const getSubjectivesObjectivesByProjectId = async (projectId, setError, setSubjectivesByProjectData) => {
    setError(null);
    setSubjectivesByProjectData([]); // Reset Subjectives data to an empty array

    if (!projectId) {
      setError('ProjectId parameter is required');
      return;
    }

    try {
      console.log(`Fetching subjective data for projectId: ${projectId}`);
      const response = await axios.get('/fxapi/subjectives/subjectives-objectives-by-project', {
        params: { projectId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting subjective data:', items);
      setSubjectivesByProjectData(items); // Correctly update the state with received data
      console.log('Updated subjective data:', items);
    } catch (error) {
      console.error('Error fetching subjective data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch subjectives data');
    }
};

const getSubjectivesByObjectiveId = async (objectiveId, setError, setSubjectivesByObjectiveData) => {
  setError(null);
  setSubjectivesByObjectiveData([]); // Reset subjectives data to an empty array

  if (!objectiveId) {
    setError('ObjectiveId parameter is required');
    return;
  }

  console.log('ran subjectives by objective id');

  try {
    console.log(`Fetching subjective data for objectiveId: ${objectiveId}`);
    const response = await axios.get('/fxapi/subjectives/subjectives-by-objective', {
      params: { objectiveId }
    });
    console.log('API response received for subjective data by objectiveId:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting subjectives data:', items);
    setSubjectivesByObjectiveData(items); // Correctly update the state with received data
    console.log('Updated subjective data:', items);
  } catch (error) {
    console.error('Error fetching subjective data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch subjectives data');
  }
};

const createSubjective = async (name, description, related_org, related_objective, related_project, setSubjectiveResponse) => {
  try {
    // Generate projectId
    const subjectiveId = generateUUID();

    // Simple validation
    if (!subjectiveId || !name || !description || !related_org) {
      console.warn('Missing required parameters: subjectiveId, name, description, or related_org.');
      return;
    }

    console.log(`Creating project for orgId: ${related_org}, projectName: ${name}`);

    // Send request
    const response = await axios.post('/fxapi/subjectives/create', {
      subjective_id: subjectiveId,
      name,
      description,
      related_org,
      related_project,
      related_objective
    });

    console.log('API response received:', response.data);

    // Update state with response data
    setSubjectiveResponse(response.data);

  } catch (error) {
    // Suppress the error, just log it quietly
    console.error('Error creating subjective:', error);
    // Optionally, handle specific errors here without alerting the user
  }
};

const updateSubjective = async (subjectiveId, name, description, related_org) => {

  try {
    const response = await axios.put(`/fxapi/subjectives/update/${subjectiveId}`, {
      subjective_id: subjectiveId,
      name: name,
      description: description,
      related_org: related_org
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

const deleteSubjective = async (subjectiveId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!subjectiveId) {
    setError('subjectiveId parameter is required');
    return;
  }

  try {
    console.log(`Deleting subjective with ID: ${subjectiveId}`);
    const response = await axios.delete(`/fxapi/subjectives/delete/${subjectiveId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted subjective with ID: ${subjectiveId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting subjective:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete subjective';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};


// ----------------------

// Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  
  export { getSubjectivesObjectivesByOrgId, getSubjectivesObjectivesByProjectId, getSubjectivesByObjectiveId, createSubjective, updateSubjective, deleteSubjective };
