import axios from 'axios';

const createIntegration = async (product, api_key, username, url, connection_config, related_project, related_org, setIntegrationResponse) => {
    try {
      // Generate projectId
      const integrationId = generateUUID();
  
      // Simple validation
      if (!integrationId || !related_org) {
        console.warn('Missing required parameters: integrationId, or related_org.');
        return;
      }
  
      console.log(`Creating integration for orgId: ${related_org}`);
  
      // Send request
      const response = await axios.post('/fxapi/integrations/create', {
        integration_id: integrationId,
        product,
        api_key,
        username,
        url,
        connection_config,
        related_project,
        related_org
      });
  
      console.log('API response received:', response.data);
  
      // Update state with response data
      setIntegrationResponse(response.data);
  
    } catch (error) {
      // Suppress the error, just log it quietly
      console.error('Error creating integration:', error);
      // Optionally, handle specific errors here without alerting the user
    }
  };

  const getIntegrationsByOrgId = async (orgId, setError, setIntegrationsByOrgData) => {
    setError(null);
    setIntegrationsByOrgData([]); // Reset Anchors data to an empty array

    if (!orgId) {
      setError('orgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching integration data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/integrations/integrations-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting integration data:', items);
      setIntegrationsByOrgData(items); // Correctly update the state with received data
      console.log('Updated integration data:', items);
    } catch (error) {
      console.error('Error fetching anchor data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch anchors data');
    }
};

const deleteIntegration = async (integrationId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!integrationId) {
      setError('integrationId parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting integration with ID: ${integrationId}`);
      const response = await axios.delete(`/fxapi/integrations/delete/${integrationId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted integration with ID: ${integrationId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting integration:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete integration';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  const updateIntegration = async (integrationId, product, api_key, username, url, connection_config, related_project, related_org) => {

    try {
      const response = await axios.put(`/fxapi/integrations/update/${integrationId}`, {
        integration_id: integrationId,
        product,
        api_key,
        username,
        url,
        connection_config,
        related_project,
        related_org
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  // Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  
  export { createIntegration, getIntegrationsByOrgId, deleteIntegration, updateIntegration};