import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './CompCRUDFormSubjective.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createObjective, updateObjective, getViewObjectivesProjectsByOrgId } from '../fxAPI/fxObjectivesAPI.js';
import { createSubjective, updateSubjective, getSubjectivesObjectivesByOrgId } from '../fxAPI/fxSubjectivesAPI.js';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CompCRUDFormSubjective = (props) => {

  const [error, setError] = useState(null);

  const params = useParams();

  /* OBJECTIVES */
  const [viewObjectivesProjectsByOrg, setViewObjectivesProjectsByOrgData] = useState([]); // Initialize as an array
  /* get objectives by orgId */
  const fxAPIFetchViewObjectivesProjectsByOrgId = async (orgId) => {
    await getViewObjectivesProjectsByOrgId(orgId, setError, setViewObjectivesProjectsByOrgData);
    console.log('all view objectives projects by org Id');
    console.dir(viewObjectivesProjectsByOrg);
  };

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [newRelatedObjectiveId, setNewRelatedObjectiveId] = useState('');
  const [newSubjectiveName, setNewSubjectiveName] = useState('');
  const [newSubjectiveDescription, setNewSubjectiveDescription] = useState('');
  const [updateSubjectiveName, setUpdateSubjectiveName] = useState(props.currentComponentData.subjective_view_name);
  const [updateSubjectiveDescription, setUpdateSubjectiveDescription] = useState(props.currentComponentData.subjective_view_description);

  console.log('current objective data on form objective: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleUpdateSubjectiveNameChange = (e) => {
    setUpdateSubjectiveName(e.target.value);
  }

  const handleUpdateSubjectiveDescriptionChange = (e) => {
    setUpdateSubjectiveDescription(e.target.value);
  }

  const handleCreateSubjective = async (event) => {
    event.preventDefault();
    if (params.type === "projects") {
      var relatedProject = params.compId;
    } else {
      var relatedProject = '';
    }
  
    const related_org = localStorage.getItem("fxLocalOrgID");
  
    const name = newSubjectiveName;
    const description = newSubjectiveDescription;
    const related_objective = newRelatedObjectiveId;
    const related_project = relatedProject;
  
    // Log values to verify they are correct
    console.log("Creating subjective with values: ", {
      name, description, related_org, related_objective, related_project
    });
  
    await createSubjective(name, description, related_org, related_objective, related_project);
  };

  const handleUpdateSubjective = async (e) => {
    console.log('ran update subjective: ' + props.currentComponentData.subjective_id);
    e.preventDefault();
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const subjectiveId = props.currentComponentData.subjective_id;
    const name = updateSubjectiveName;
    const description = updateSubjectiveDescription;
    const related_org = currentOrgId;
  
    await updateSubjective(subjectiveId, name, description, related_org);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
  }, []);

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateSubjective}>
              <div>
              <label for="objective-select">Choose an objective:</label>

              <select name="objective-select" id="objective-select" className='fx-control-custom-select' onChange={(e) => setNewRelatedObjectiveId(e.target.value)}>
                {viewObjectivesProjectsByOrg.map((itemObjective) => (
                  <option value={itemObjective.objective_id}>{itemObjective.objective_view_name}</option>
                ))}
              </select>
              </div>
                <br />
              <div>
                <TextField
                  id="outlined-basic"
                  label="Subjective Name"
                  variant="outlined"
                  onChange={(e) => setNewSubjectiveName(e.target.value)}
                />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Subjective Description"
                multiline
                maxRows={4}
                onChange={(e) => setNewSubjectiveDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Create Subjective</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              {/*<div>Test: {props.currentComponentData.objective_id}</div>*/}
              <div>
                <div className='fx-global-form-read-only-label'>Subjective Name</div>
                <div>{props.currentComponentData.subjective_view_name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Subjective Description</div>
                <div>{props.currentComponentData.subjective_view_description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateSubjective}>
                <div>
                  <TextField id="outlined-basic" label="Subjective Name" variant="outlined" value={updateSubjectiveName} onChange={handleUpdateSubjectiveNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Subjective Description"
                  value={updateSubjectiveDescription}
                  onChange={handleUpdateSubjectiveDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type="submit">Save Subjective</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormSubjective;