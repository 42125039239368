import React, { useState, useEffect } from 'react';
import './CompUserAssignment.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompMiniAvatar from './CompMiniAvatar';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Popover from '@mui/material/Popover';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getUsersByOrgId } from '../fxAPI/fxUsersAPI.js';

const CompUserAssignment = (props) => {
  const [error, setError] = useState(null);
  const [usersByOrgId, setUsersByOrgData] = useState([]);
  const [assignedUser, setAssignedUser] = useState(props.userAssigned);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event) => {
    console.log('Selected User ID:', event.target.value); // Debugging log
    setAssignedUser(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log('user assignment for: ' + props.componentType);
    console.dir(props.componentData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const fetchUsers = async () => {
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      await fxAPIFetchUsersByOrgId(currentOrgId);
    };
    fetchUsers();
  }, []);

  const fxAPIFetchUsersByOrgId = async (orgId) => {
    await getUsersByOrgId(orgId, setError, setUsersByOrgData);
    console.log('Fetched Users:', usersByOrgId); // Debugging log
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="fx-user-assign-popover-container"
        PaperProps={{
          style: {
            padding: '16px', // Adjust padding
            minWidth: '200px' // Adjust min-width
          },
        }}
      >
        <div className="fx-user-assign-popover-row">
          <FormControl fullWidth>
            <InputLabel id="user-assigned-label">Assign To</InputLabel>
            <Select
              labelId="user-assigned-label"
              id="user-assigned"
              value={assignedUser}
              label="Assign To"
              onChange={handleChange}
            >
              {usersByOrgId.map((user) => (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {`${user.first_name} ${user.last_name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button>Assign</Button>
        </div>
      </Popover>
      {!props.userAssigned &&
      <div aria-describedby={id} onClick={handleClick}>
        <PersonAddIcon />
      </div>
       }
       {props.userAssigned &&
      <div>
        <CompMiniAvatar userId={props.userAssigned} />
      </div>
      }
    </div>
  );
};

export default CompUserAssignment;