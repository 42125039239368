import React, { useEffect, useState } from 'react';
import './PageProfileUser.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

export default function PageProfileUser() {

  const { isLoading, isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

const [data, setData] = useState(null);
const [error, setError] = useState(null);
const [success, setSuccess] = useState(null); // State for success message
const [username, setUsername] = useState('');
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [userData, setUserData] = useState(null);
const [profileFirstName, setProfileFirstName] = useState(null);
const [profileLastName, setProfileLastName] = useState(null);

const getUserByEmail = async (email) => {
  setError(null);
  setUserData(null);

  if (!email) {
    setError('Email parameter is required');
    return;
  }

  try {
    console.log(`Fetching user data for email: ${email}`);
    const response = await axios.get('/fxapi/users/user-by-email', {
      params: { email }
    });
    setUserData(response.data); // Set the userData with the response data
    console.log('profile user data: ');
    console.dir(response.data); // Log the response data directly
    console.log('items:');
    console.dir(response.data.items);
    if (response.data.items && response.data.items.length > 0) {
      const firstName = response.data.items[0].first_name;
      console.log('First name:', firstName); // Output the first name
      const lastName = response.data.items[0].last_name;
      console.log('Last name:', lastName); // Output the first name
      setProfileFirstName(firstName);
      setProfileLastName(lastName);
    } else {
      console.log('No user data available');
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch user data');
  }
};

//getUserByEmail(user.name);

useEffect(() => {
  const currentUserID = localStorage.getItem("fxLocalUserID");
  const currentUsername = localStorage.getItem("fxLocalUserName");
  console.log('current user id: ' + currentUserID);
  console.log('current user name: ' + currentUsername);

  if (isAuthenticated && user) {
    console.log('user logged in: ');
    console.dir({ user });

    getUserByEmail(user.name);
  }

}, [user]);

  
  

  
  const storedUserFirstName = localStorage.getItem("fxLocalUserFirstName");
  const welcomeMessage = "Welcome " + storedUserFirstName;

  return (
    <CompPageTempMaster3Panel headingOne={welcomeMessage}>
      <CompContentBlock headingThree="Profile Info" variant="uxc-variant-read-only-block">
        <div className="fx-page-read-only-group-row">
          {profileFirstName &&
            <div className="fx-page-read-only-col">
                <div>First Name</div>
                <div>{profileFirstName}</div>
            </div>
          }
          {profileLastName &&
            <div className="fx-page-read-only-col">
                <div>Last Name</div>
                <div>{profileLastName}</div>
            </div>
          }
        </div>
      </CompContentBlock>
    </CompPageTempMaster3Panel>
  );
}