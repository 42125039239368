import axios from 'axios';

const getComStatusByOrgId = async (orgId, setError, setComStatusData) => {
    setError(null);
    setComStatusData([]); // Reset user personas data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching status data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/status/status-by-org', {
        params: { orgId }
      });
      console.log('API response received1:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting status data 1:', items);
      setComStatusData(items); // Correctly update the state with received data
      console.log('Updated status data 1:', items);
    } catch (error) {
      console.error('Error fetching status data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
    }
};

const getComStatusByProjectId = async (projectId, setError, setComStatusDataByProject) => {
  setError(null);
  setComStatusDataByProject([]); // Reset user personas data to an empty array

  if (!projectId) {
    setError('ProjectId parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for projectId: ${projectId}`);
    const response = await axios.get('/fxapi/status/status-by-project', {
      params: { projectId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status data 1:', items);
    setComStatusDataByProject(items); // Correctly update the state with received data
    console.log('Updated status data 1:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
  }
};

const getComStatusByObjectiveId = async (objectiveId, setError, setComStatusByObjectiveData) => {
  setError(null);
  setComStatusByObjectiveData([]); // Reset user personas data to an empty array

  if (!objectiveId) {
    setError('Objective Id parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for objectiveId: ${objectiveId}`);
    const response = await axios.get('/fxapi/status/status-by-objective', {
      params: { objectiveId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status data 1:', items);
    setComStatusByObjectiveData(items); // Correctly update the state with received data
    console.log('Updated status data 1:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
  }
};

const getComStatusByUserPersonaId = async (userPersonaId, setError, setComStatusByUserPersonaData) => {
  setError(null);
  setComStatusByUserPersonaData([]); // Reset user personas data to an empty array

  if (!userPersonaId) {
    setError('User Persona Id parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for user persona Id: ${userPersonaId}`);
    const response = await axios.get('/fxapi/status/status-by-user-persona', {
      params: { userPersonaId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status data 1:', items);
    setComStatusByUserPersonaData(items); // Correctly update the state with received data
    console.log('Updated status data 1:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
  }
};

const getComStatusTaskByUserId = async (userId, setError, setComStatusDataByUser) => {
  setError(null);
  setComStatusDataByUser([]); // Reset user personas data to an empty array

  if (!userId) {
    setError('UserId parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for userId: ${userId}`);
    const response = await axios.get('/fxapi/status/status-task-by-user', {
      params: { userId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status data 1:', items);
    setComStatusDataByUser(items); // Correctly update the state with received data
    console.log('Updated status data 1:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
  }
};

const getComStatusTaskByOrgId = async (orgId, setError, setComStatusTaskDataByOrg) => {
  setError(null);
  setComStatusTaskDataByOrg([]); // Reset user personas data to an empty array

  if (!orgId) {
    setError('OrgId parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/status/status-task-by-org', {
      params: { orgId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status data 1:', items);
    setComStatusTaskDataByOrg(items); // Correctly update the state with received data
    console.log('Updated status data 1:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
  }
};

const getComStatusObjectiveByUserAssignedId = async (userAssignedId, setError, setComStatusObjectiveByUserAssignData) => {
  setError(null);
  setComStatusObjectiveByUserAssignData([]); // Reset data to an empty array

  if (!userAssignedId) {
    setError('User Id parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for userId: ${userAssignedId}`);
    const response = await axios.get('/fxapi/status/status-objective-by-user-assigned', {
      params: { userAssignedId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status data:', items);
    setComStatusObjectiveByUserAssignData(items); // Correctly update the state with received data
    console.log('Updated status data:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status data');
  }
};



const getComStatusObjectiveByRequestorId = async (requestorId, setError, setComStatusObjectiveByRequestorData) => {
  setError(null);
  setComStatusObjectiveByRequestorData([]); // Reset user personas data to an empty array

  if (!requestorId) {
    setError('Requestor Id parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for requestorId: ${requestorId}`);
    const response = await axios.get('/fxapi/status/status-objective-by-requestor', {
      params: { requestorId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status data 1:', items);
    setComStatusObjectiveByRequestorData(items); // Correctly update the state with received data
    console.log('Updated status data 1:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
  }
};

const getComStatusProjectByOrgId = async (orgId, setError, setComStatusProjectByOrgData) => {
  setError(null);
  setComStatusProjectByOrgData([]); // Reset user personas data to an empty array

  if (!orgId) {
    setError('OrgId parameter is required');
    return;
  }

  try {
    console.log(`Fetching status data for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/status/status-project-by-org', {
      params: { orgId }
    });
    console.log('API response received1:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting status project by org Id data 1:', items);
    setComStatusProjectByOrgData(items); // Correctly update the state with received data
    console.log('Updated status project by org Id data 1:', items);
  } catch (error) {
    console.error('Error fetching status data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch status 1 data');
  }
};

const updateStatus  = async (statusId, title, status_current, description, related_org, resolved) => {

  try {
    const response = await axios.put(`/fxapi/status/update/${statusId}`, {
      status_id: statusId,
      title: title,
      status_current: status_current,
      description: description,
      related_org: related_org,
      resolved: resolved
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

const createStatus = async (related_component, related_org, related_project, user_assigned, requestor, title, description, related_component_type, setStatusResponse) => {
  try {
    // Generate projectId
    const statusId = generateUUID();

    // Simple validation
    if (!statusId) {
      console.warn('Missing required parameters: statusId');
      return;
    }

    console.log(`Creating status for orgId: ${related_org}`);

    // Send request
    const response = await axios.post('/fxapi/status/create', {
      status_id: statusId,
      related_component,
      related_org,
      related_project,
      user_assigned,
      requestor,
      title,
      description,
      related_component_type
    });

    console.log('API response received:', response.data);

    // Update state with response data
    setStatusResponse(response.data);

  } catch (error) {
    // Suppress the error, just log it quietly
    console.error('Error creating task:', error);
    // Optionally, handle specific errors here without alerting the user
  }
};

const deleteStatus = async (statusId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!statusId) {
    setError('statusId parameter is required');
    return;
  }

  try {
    console.log(`Deleting status with ID: ${statusId}`);
    const response = await axios.delete(`/fxapi/status/delete/${statusId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted status with ID: ${statusId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting status:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete status';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

// Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export { getComStatusByOrgId, getComStatusByProjectId, getComStatusTaskByUserId, getComStatusTaskByOrgId, getComStatusProjectByOrgId, getComStatusByObjectiveId, getComStatusByUserPersonaId, deleteStatus, updateStatus, createStatus, getComStatusObjectiveByUserAssignedId, getComStatusObjectiveByRequestorId };

/*

*/