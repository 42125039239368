import React, { useEffect, useState } from 'react';
import './CompUIAssetsGallery.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompUIAssetsGalleryItem from './CompUIAssetsGalleryItem';
import CompListViewActionBar from './CompListViewActionBar';
import CompComponentList from './CompComponentList';
import CompComponentListItem from './CompComponentListItem';
import { getUIAssetsByOrgId, getUIAssetsByProjectId } from '../fxAPI/fxUIAssetsAPI.js';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import CompCRUDModal from './CompCRUDModal';



const CompUIAssetsGallery = (props) => {

  const [currentProject, setCurrentProject] = React.useState(props.relatedProject);

  const handleProjectChange = (event) => {
    setCurrentProject(event.target.value);
    fxAPIFetchUIAssetsByProjectId(event.target.value);
  };

  const [error, setError] = useState(null);

  const [listViewState, setListViewState] = useState('grid');

  const handleViewActionBarViewChange = (newViewState) => {
    setListViewState(newViewState);
  };

  /* Projects by Org */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  /* get UI Assets by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  /* UI Assets */
  const [uiAssetsByOrgId, setUIAssetsByOrgData] = useState([]); // Initialize as an array
  /* get UI Assets by orgId */
  const fxAPIFetchUIAssetsByOrgId = async (orgId) => {
    await getUIAssetsByOrgId(orgId, setError, setUIAssetsByOrgData);
    console.log('all ui assets by org Id');
    console.dir(uiAssetsByOrgId);
  };

  /* UI Assets */
  const [uiAssetsByProjectId, setUIAssetsByProjectData] = useState([]); // Initialize as an array
  /* get UI Assets by projectId */
  const fxAPIFetchUIAssetsByProjectId = async (projectId) => {
    await getUIAssetsByProjectId(projectId, setError, setUIAssetsByProjectData);
    console.log('all ui assets by project Id');
    console.dir(uiAssetsByProjectId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchProjectsByOrgId(currentOrgId);
    fxAPIFetchUIAssetsByOrgId(currentOrgId);
    if(currentProject) {
      fxAPIFetchUIAssetsByProjectId(currentProject);
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState(false);
  const [modalItemType, setModalItemType] = useState('ui-asset');

      const openModal = (currentModalMode, currentModalItemType) => {
        setModalMode(currentModalMode);
        setModalItemType(currentModalItemType);
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };

  return (
    <>
      <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle='Create UI Asset' itemType='ui-asset' crudMethod='create'></CompCRUDModal>
      <div>{props.relatedProject}</div>
      <br />
      <div><Button onClick={openModal}>Create UI Asset</Button></div>
      <br />
      <div className='fx-comp-ui-assets-gallery-picker'>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Project</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentProject}
            label="Project"
            onChange={handleProjectChange}
          >
            {projectsByOrgId.map((project) => (
            <MenuItem value={project.project_id}>{project.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    {/* {listViewState}*/}
    {/* <CompListViewActionBar onViewChange={handleViewActionBarViewChange} /> */}
    {/* Component List View */}
    {listViewState === "list" &&
      <CompComponentList>
        <CompComponentListItem itemLabel="Login Form" />
      </CompComponentList>
    }
    {/* Gallery View */}
    {listViewState === "grid" &&
      <div className="fx-comp-ui-assets-gallery-container">
        {uiAssetsByProjectId.map((uiAssetItem) => (
          <CompUIAssetsGalleryItem uiTitle={uiAssetItem.name} uiImgSrc={uiAssetItem.img_full_url} componentData={uiAssetItem} relatedProject={props.relatedProject} />
        ))}
      </div>
    }
    </>
  );
};

export default CompUIAssetsGallery;