import React, { useEffect, useState } from 'react';
import './PageHome.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import axios from 'axios';

export default function PageHome() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // State for success message
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/fxapi/users/all');
        setData(response.data);
      } catch (error) {
        setError('Error fetching data');
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      console.dir(data);
    }
  }, [data]); // Dependency array to ensure it runs when data is updated


  async function handleCreateUser(event) {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await axios.post('/fxapi/users/individual/create', {
        username,
        email,
        password,
      });
      console.log('User created:', response.data);
      setSuccess('User successfully created!'); // Set success message
      setUsername('');
      setEmail('');
      setPassword('');
      // Optionally, refetch data or update state to reflect the new user
    } catch (error) {
      setError('Error creating user');
      console.error(error);
    }
  }

  const storedUserFirstName = localStorage.getItem("fxLocalUserFirstName");
  const welcomeMessage = "Welcome " + storedUserFirstName;

  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  const handleFileChange = (event) => {
    setImageFile(event.target.files[0]);
    console.log('set image file test: ');
    console.dir(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!imageFile) return;
  
    const formData = new FormData();
    formData.append('file', imageFile);
  
    try {
      const response = await fetch('/fxapi/upload-image', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Server Response:', data);
  
      if (data && data.result && data.result.variants) {
        const cdnUrl = data.result.variants[0];
        setImageUrl(cdnUrl);
      } else {
        throw new Error('Invalid response structure or upload failed');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <CompPageTempMaster3Panel headingOne={welcomeMessage}>
      <CompContentBlock headingThree="Screens">
        <CompFeatureCarousel />
      </CompContentBlock>

      <div>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleUpload}>Upload</button>
                {imageUrl && <img src={imageUrl} alt="Uploaded" />}
              </div>


      {/*
      {error && <div>{error}</div>}
      {success && <div>{success}</div>}
      {data ? (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      ) : (
        <p>Loading...</p>
      )}
      */}
      {/*
      <form onSubmit={handleCreateUser}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Create User</button>
      </form>
    */}
    </CompPageTempMaster3Panel>
  );
}