import React from 'react';
import './CompCRUDModalButtons.css'; // Create this CSS file for styling

const CompModalButtons = (props) => {


  return (
    <div className="fx-comp-crud-modal-buttons-outer">
        {props.children}
    </div>
  );
};

export default CompModalButtons;