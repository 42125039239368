import React, { useState, useEffect, useContext } from 'react';
import './CompActionTracker.css';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { PanelContext } from '../Contexts/PanelContext';
import Slider from '@mui/material/Slider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getComStatusByOrgId, getComStatusTaskByUserId, getComStatusTaskByOrgId, getComStatusObjectiveByRequestorId, getComStatusObjectiveByUserAssignedId } from '../fxAPI/fxStatusAPI.js';
import Chip from '@mui/material/Chip';
import CompMiniAvatar from './CompMiniAvatar.jsx';

const CompActionTracker = (props) => {

  const [error, setError] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /* STATUS */
  const [statusByOrgId, setStatusByOrgData] = useState([]); // Initialize as an array
  /* get user status by orgId */
  const fxAPIFetchStatusByOrgId = async (orgId) => {
    await getComStatusByOrgId(orgId, setError, setStatusByOrgData);
    console.log('all status by org Id');
    console.dir(statusByOrgId);
  };

  /* STATUS */

  /* STATUS - OBJECTIVE BY USER ASSIGNED ID */
 const [comStatusObjectiveDataByUserAssigned, setComStatusObjectiveByUserAssignData] = useState([]); // Initialize as an array
 /* get status by user assigned */
 const fxAPIFetchComStatusObjectiveByUserAssignedId  = async (userId) => {
   await getComStatusObjectiveByUserAssignedId(userId, setError, setComStatusObjectiveByUserAssignData);
   console.log('all view status objective by user assigned Id');
   console.dir(comStatusObjectiveDataByUserAssigned);
 };

 /* STATUS - OBJECTIVE BY USER ASSIGNED ID */
 const [comStatusObjectiveByRequestorData, setComStatusObjectiveByRequestorData] = useState([]); // Initialize as an array
 /* get status by user assigned */
 const fxAPIFetchComStatusObjectiveByRequestorData  = async (userId) => {
   await getComStatusObjectiveByRequestorId(userId, setError, setComStatusObjectiveByRequestorData);
   console.log('all view status objective by user assigned Id');
   console.dir(comStatusObjectiveByRequestorData);
 };

 /* DATA GRID COLUMNS AND ROWS */

 /* My open updates */
 const columnsMyOpenUpdates = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'requestor',
    headerName: 'Requestor',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'objective_view_name', headerName: 'Objective', width: 250 },
  {
    field: 'response',
    headerName: 'Response',
    width: 300,
    renderCell: (params) => (
      <div><Chip className="fx-page-dashboard-action-pill-working" label="Working" /><Chip className="fx-page-dashboard-action-pill-done" label="Done" /></div>
    ),
  }
];

// Map the API data to the objective rows
const rowsMyOpenUpdates = comStatusObjectiveDataByUserAssigned
  .filter((statusItem) => statusItem.resolved?.trim().toLowerCase() !== "yes")
  .map((item, index) => ({
    id: item.status_id || index, // Use status_id or index as the ID for each row
    status_view_title: item.status_view_title || 'N/A', // Ensure valid data
    requestor: item.requestor || 'N/A', // Ensure valid data
    objective_view_name: item.objective_view_name || 'N/A', // Ensure valid data
    response: '...' // Placeholder for other data
  }));

/* My resolved updates */
const columnsMyResolvedUpdates = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'requestor',
    headerName: 'Requestor',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'objective_view_name', headerName: 'Objective', width: 250 },
  {
    field: 'response',
    headerName: 'Response',
    width: 300,
    renderCell: (params) => (
      <div><Chip className="fx-page-dashboard-action-pill-working" label="Working" /><Chip className="fx-page-dashboard-action-pill-done" label="Done" /></div>
    ),
  }
];

// Map the API data to the objective rows
const rowsMyResolvedUpdates = comStatusObjectiveDataByUserAssigned
  .filter((statusItem) => statusItem.resolved?.trim().toLowerCase() === "yes")
  .map((item, index) => ({
  id: item.status_id || index, // Use subjective_id as the ID for each row
  status_view_title: item.status_view_title || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  requestor: item.requestor || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  objective_view_name: item.objective_view_name || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  response: '...'
}));

/* My pending requests */
const columnsMyPendingRequests = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'user_assigned',
    headerName: 'Assigned To',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'objective_view_name', headerName: 'Objective', width: 250 },
  {
    field: 'response',
    headerName: 'Response',
    width: 300,
    renderCell: (params) => (
      <div><Chip className="fx-page-dashboard-action-pill-working" label="Working" /><Chip className="fx-page-dashboard-action-pill-done" label="Done" /></div>
    ),
  }
];

// Map the API data to the objective rows
const rowsMyPendingRequests = comStatusObjectiveByRequestorData
.filter((statusItem) => statusItem.resolved?.trim().toLowerCase() !== "yes")
.map((item, index) => ({
  id: item.status_id || index, // Use subjective_id as the ID for each row
  status_view_title: item.status_view_title || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  user_assigned: item.user_assigned || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  objective_view_name: item.objective_view_name || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  response: '...'
}));

/* My resolved requests */
const columnsMyResolvedRequests = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'user_assigned',
    headerName: 'Assigned To',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'objective_view_name', headerName: 'Objective', width: 250 },
  {
    field: 'response',
    headerName: 'Response',
    width: 300,
    renderCell: (params) => (
      <div><Chip className="fx-page-dashboard-action-pill-working" label="Working" /><Chip className="fx-page-dashboard-action-pill-done" label="Done" /></div>
    ),
  }
];

// Map the API data to the objective rows
const rowsMyResolvedRequests = comStatusObjectiveByRequestorData
.filter((statusItem) => statusItem.resolved?.trim().toLowerCase() === "yes")
.map((item, index) => ({
  id: item.status_id || index, // Use subjective_id as the ID for each row
  status_view_title: item.status_view_title || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  user_assigned: item.user_assigned || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  objective_view_name: item.objective_view_name || 'N/A', // Replace 'objective_view_name' with the actual field from your data
  response: '...'
}));


 useEffect(() => {
  const currentOrgId = localStorage.getItem("fxLocalOrgID");
  //fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
  //fxAPIFetchTasksByOrgId(currentOrgId);

  fxAPIFetchStatusByOrgId(currentOrgId);
  //fxAPIFetchStatusTaskByOrgId(currentOrgId);
  const storedLocalUserID = localStorage.getItem("fxLocalUserID");
  console.log('get status by assigned for action tracker: ' + 'for: ' + storedLocalUserID);
  console.dir(comStatusObjectiveDataByUserAssigned);
  fxAPIFetchComStatusObjectiveByUserAssignedId(storedLocalUserID);
  fxAPIFetchComStatusObjectiveByRequestorData(storedLocalUserID);

  }, []);

  const paginationModel = { page: 0, pageSize: 5 };

  return (
    <>
    <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="My Open Updates" />
              <Tab label="My Resolved Updates" />
              <Tab label="My Pending Requests" />
              <Tab label="My Resolved Requests" />
              
    </Tabs>
    {tabValue === 0 && (

    <div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rowsMyOpenUpdates}
          columns={columnsMyOpenUpdates}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </div>

    )}

    {tabValue === 1 && (

    <div>
    <Paper sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rowsMyResolvedUpdates}
        columns={columnsMyResolvedUpdates}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        sx={{ border: 0 }}
      />
    </Paper>
    </div>

    )}

    {tabValue === 2 && (

    <div>
    <Paper sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rowsMyPendingRequests}
        columns={columnsMyPendingRequests}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        sx={{ border: 0 }}
      />
    </Paper>
    </div>

    )}

    {tabValue === 3 && (

    <div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rowsMyResolvedRequests}
          columns={columnsMyResolvedRequests}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </div>

    )}
    </>
  );
};

export default CompActionTracker;