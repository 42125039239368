import React, { useEffect, useState } from 'react';
import './CompFeatureCarousel.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompFeatureCard from './CompFeatureCard';
import { getUIAssetsByProjectId, getUIAssetsByOrgId } from '../fxAPI/fxUIAssetsAPI';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const CompFeatureCarousel = (props) => {
  const [error, setError] = useState(null);
  const [uiAssets, setUIAssets] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerPage = 4;

  /* get ui assets by orgId */
  const fxAPIFetchUIAssetsByProjectId = async (projectId) => {
    await getUIAssetsByProjectId(projectId, setError, setUIAssets);
  };

  /* get ui assets by orgId */
  const fxAPIFetchUIAssetsByOrgId = async (orgId) => {
    await getUIAssetsByOrgId(orgId, setError, setUIAssets);
  };

  useEffect(() => {
    if(props.projectId === "all") {
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      fxAPIFetchUIAssetsByOrgId(currentOrgId);
    } else {
      fxAPIFetchUIAssetsByProjectId(props.projectId);
    }
    
  }, [props.projectId]);

  const next = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + itemsPerPage >= uiAssets.length ? 0 : prevIndex + itemsPerPage)
    );
  };

  const prev = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - itemsPerPage < 0 ? uiAssets.length - itemsPerPage : prevIndex - itemsPerPage)
    );
  };

  return (
    <div className="fx-comp-feature-carousel-container">
      <div className="fx-comp-feature-carousel-toolset-l" onClick={prev}>
        <ChevronLeft />
      </div>
      <div className="fx-comp-feature-carousel-content" style={{ transform: `translateX(-${(currentIndex * 100) / itemsPerPage}%)` }}>
        {uiAssets.map((uiAsset, index) => (
          <div key={uiAsset.ui_asset_id} className="fx-comp-feature-carousel-item-outer">
            <CompFeatureCard cardTitle={uiAsset.name} cardNumber="#" cardLinkPath={`/ui-asset-detail/${uiAsset.ui_asset_id}`} cardLinkTitle="Open" cardThumb={uiAsset.img_full_url} componentData={uiAsset} />
            
          </div>
        ))}
      </div>
      <div className="fx-comp-feature-carousel-toolset-r" onClick={next}>
        <ChevronRight />
      </div>
    </div>
  );
};

export default CompFeatureCarousel;