import axios from 'axios';

const getTasksByOrgId = async (orgId, setError, setTasksData) => {
    setError(null);
    setTasksData([]); // Reset Tasks data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching task data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/tasks/tasks-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting tasks data:', items);
      setTasksData(items); // Correctly update the state with received data
      console.log('Updated task data:', items);
    } catch (error) {
      console.error('Error fetching task data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch tasks data');
    }
};

const getTasksByProjectId = async (projectId, setError, setTasksByProjectData) => {
    setError(null);
    setTasksByProjectData([]); // Reset Tasks data to an empty array

    if (!projectId) {
      setError('ProjectId parameter is required');
      return;
    }

    try {
      console.log(`Fetching task data for projectId: ${projectId}`);
      const response = await axios.get('/fxapi/tasks/tasks-by-project', {
        params: { projectId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting tasks data:', items);
      setTasksByProjectData(items); // Correctly update the state with received data
      console.log('Updated task data:', items);
    } catch (error) {
      console.error('Error fetching task data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch tasks data');
    }
};

const getTasksByObjectiveId = async (objectiveId, setError, setTasksByObjectiveData) => {
  setError(null);
  setTasksByObjectiveData([]); // Reset Tasks data to an empty array

  if (!objectiveId) {
    setError('ObjectiveId parameter is required');
    return;
  }

  console.log('ran tasks by objective id');

  try {
    console.log(`Fetching task data for objectiveId: ${objectiveId}`);
    const response = await axios.get('/fxapi/tasks/tasks-by-objective', {
      params: { objectiveId }
    });
    console.log('API response received for task data by objectiveId:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting tasks data:', items);
    setTasksByObjectiveData(items); // Correctly update the state with received data
    console.log('Updated task data:', items);
  } catch (error) {
    console.error('Error fetching task data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch tasks data');
  }
};

const getTasksByUserPersonaId = async (userPersonaId, setError, setTasksByUserPersonaData) => {
  setError(null);
  setTasksByUserPersonaData([]); // Reset Tasks data to an empty array

  if (!userPersonaId) {
    setError('User Persona Id parameter is required');
    return;
  }

  try {
    console.log(`Fetching task data for userPersonaId: ${userPersonaId}`);
    const response = await axios.get('/fxapi/tasks/tasks-by-user-persona', {
      params: { userPersonaId }
    });
    console.log('API response received for task data by userPersonaId:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting tasks data by user persona:', items);
    setTasksByUserPersonaData(items); // Correctly update the state with received data
    console.log('Updated task data by user persona:', items);
  } catch (error) {
    console.error('Error fetching task data by user persona:', error);
    setError(error.response ? error.response.data : 'Failed to fetch tasks by user persona data');
  }
};

const getTaskProjectByOrgId = async (orgId, setError, setTaskProjectByOrgData) => {
  setError(null);
  setTaskProjectByOrgData([]); // Reset Tasks data to an empty array

  if (!orgId) {
    setError('Org Id parameter is required');
    return;
  }

  try {
    console.log(`Fetching task data for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/tasks/task-project-by-org', {
      params: { orgId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting task project by org id data:', items);
    setTaskProjectByOrgData(items); // Correctly update the state with received data
    console.log('Updated task project by org id data:', items);
  } catch (error) {
    console.error('Error fetching task data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch tasks data');
  }
};

const getTaskProjectByProjectId = async (projectId, setError, setTaskProjectByProjectData) => {
  setError(null);
  setTaskProjectByProjectData([]); // Reset Tasks data to an empty array

  if (!projectId) {
    setError('Project Id parameter is required');
    return;
  }

  try {
    console.log(`Fetching task data for orgId: ${projectId}`);
    const response = await axios.get('/fxapi/tasks/task-project-by-project', {
      params: { projectId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting task project by project id data:', items);
    setTaskProjectByProjectData(items); // Correctly update the state with received data
    console.log('Updated task project by project id data:', items);
  } catch (error) {
    console.error('Error fetching task data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch tasks data');
  }
};

const createTask = async (title, description, related_org, related_component, related_component_type, setTaskResponse) => {
  try {
    // Generate projectId
    const taskId = generateUUID();

    // Simple validation
    if (!taskId || !title || !description || !related_org) {
      console.warn('Missing required parameters: taskId, title, description, or related_org.');
      return;
    }

    console.log(`Creating project for orgId: ${related_org}, projectName: ${title}`);

    // Send request
    const response = await axios.post('/fxapi/tasks/create', {
      task_id: taskId,
      title,
      description,
      related_org,
      related_component,
      related_component_type
    });

    console.log('API response received:', response.data);

    // Update state with response data
    setTaskResponse(response.data);

  } catch (error) {
    // Suppress the error, just log it quietly
    console.error('Error creating task:', error);
    // Optionally, handle specific errors here without alerting the user
  }
};

const updateTask  = async (taskId, title, description, related_org) => {

  try {
    const response = await axios.put(`/fxapi/tasks/update/${taskId}`, {
      task_id: taskId,
      title: title,
      description: description,
      related_org: related_org
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

const deleteTask = async (taskId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!taskId) {
    setError('taskId parameter is required');
    return;
  }

  try {
    console.log(`Deleting task with ID: ${taskId}`);
    const response = await axios.delete(`/fxapi/tasks/delete/${taskId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted task with ID: ${taskId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting task:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete task';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};


// ----------------------

// Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  
  export { getTasksByOrgId, getTasksByProjectId, getTaskProjectByOrgId, getTasksByObjectiveId, getTasksByUserPersonaId, getTaskProjectByProjectId, createTask, deleteTask, updateTask };
