import React, { useState } from 'react';
import './CompPageTempIntroSlide.css';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import CompSideNavMaster from './CompSideNavMaster';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FluxityLogo from '../images/Fluxity_Logo.svg';
import { useAuth0 } from "@auth0/auth0-react";



const CompPageTempIntroSlide = ({ panelLeftContent, panelRightContent, headingOne }) => {

    const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();

    const storedLocalUserName = localStorage.getItem("fxLocalUserName");

  return (
    <div>  
          <div >
            <Grid container>
              <Grid item xs={4}>
                <div className="fx-comp-temp-intro-slide-left-panel-outer">
                  <div className="fx-comp-temp-intro-slide-left-panel-inner">
                    <img width="auto" height="70px" alt="logo" src={FluxityLogo} />
                    {panelLeftContent}
                  </div>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="fx-comp-temp-intro-slide-right-panel-outer">
                  <div className="fx-comp-temp-intro-slide-logout-button-container">
                        {isAuthenticated &&
                            <>
                            <div className='fx-comp-header-profile-name'>{storedLocalUserName}</div>
                            <Button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} variant="outlined" className='fx-comp-header-profile'>
                            Log out
                            </Button>
                            </>
                        }
                  </div>
                  <div className="fx-comp-temp-intro-slide-right-panel-inner">
                    <h1>{headingOne}</h1>
                    {panelRightContent}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
    </div>
  );
};

export default CompPageTempIntroSlide;