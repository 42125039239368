import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageSubjectiveTemplates.css';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import axios from 'axios';
import CompCRUDModalSubjectiveTemplate from '../Components/CompCRUDModalSubjectiveTemplate';

const PageSubjectiveTemplates = () => {
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  return (
    <CompPageTempMaster3Panel headingOne="Subjective Templates">
      <div className="fx-comp-subjective-template-toolbar">
        <CompCRUDModalSubjectiveTemplate />
      </div>
      <CompContentBlock headingThree="Organization Templates">
        Org Templates...
      </CompContentBlock>
      <CompContentBlock headingThree="System Templates">
        System Templates...
      </CompContentBlock>
    </CompPageTempMaster3Panel>
  );
};

export default PageSubjectiveTemplates;