import React from 'react';
import { Avatar, Grid, Typography, Slider, Box, TextField, Paper } from '@mui/material';
import './CompUserPersonaDetail.css';



const CompUserPersonaDetail = (props) => {

  const marks = [
    { value: 0, label: '0' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];
  
  const [sliderValue1, setSliderValue1] = React.useState(50);
    const [sliderValue2, setSliderValue2] = React.useState(70);
  
    const handleSliderChange1 = (event, newValue) => {
      setSliderValue1(newValue);
    };
  
    const handleSliderChange2 = (event, newValue) => {
      setSliderValue2(newValue);
    };

  return (
    <div>
      <Grid container spacing={3} className="container">
        <Grid item xs={3} className="avatarContainer">
          <Avatar
            alt="User Avatar"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 200, height: 200 }}
          />
        </Grid>
        <Grid item xs={9}>
        <Typography gutterBottom>Metric 2</Typography>
        <Slider
          value={sliderValue2}
          onChange={handleSliderChange2}
          aria-labelledby="slider-metric2"
          valueLabelDisplay="auto"
          marks={marks}
          className="slider"
        />
         <Typography gutterBottom>Metric 2</Typography>
        <Slider
          value={sliderValue1}
          onChange={handleSliderChange1}
          aria-labelledby="slider-metric2"
          valueLabelDisplay="auto"
          marks={marks}
          className="slider"
        />
        <Typography gutterBottom>Metric 3</Typography>
        <Slider
          value={sliderValue1}
          onChange={handleSliderChange1}
          aria-labelledby="slider-metric3"
          valueLabelDisplay="auto"
          marks={marks}
          className="slider"
        />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="container">
        <Grid item xs={6}>
          <Typography variant="h6">Description 1</Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam placerat ac diam a elementum. Vivamus lacus odio, congue et est quis, auctor gravida leo. Donec ullamcorper accumsan erat sed imperdiet. Praesent ut lobortis neque. Integer tempus, nisi ac volutpat bibendum, lorem mauris luctus odio, nec condimentum lorem arcu ac lacus. Nam luctus fermentum urna quis ultrices. Morbi et nunc vel nisi pretium maximus a in eros.
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Description 1</Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam placerat ac diam a elementum. Vivamus lacus odio, congue et est quis, auctor gravida leo. Donec ullamcorper accumsan erat sed imperdiet. Praesent ut lobortis neque. Integer tempus, nisi ac volutpat bibendum, lorem mauris luctus odio, nec condimentum lorem arcu ac lacus. Nam luctus fermentum urna quis ultrices. Morbi et nunc vel nisi pretium maximus a in eros.
        </Grid>
      </Grid>
    </div>
  );
};

export default CompUserPersonaDetail;