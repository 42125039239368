import React, { useState, useEffect } from 'react';
import './CompQuickCreateObjective.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI';
import { createObjective } from '../fxAPI/fxObjectivesAPI.js';


const CompQuickCreateObjective = (props) => {

  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [newProjectId, setNewProjectId] = useState(''); // Initialize with empty string
  const [newObjectiveName, setNewObjectiveName] = useState(''); // Initialize with empty string
  const [newObjectiveDescription, setNewObjectiveDescription] = useState(''); // Initialize with empty string

  function handleToggleItem(toggleValue) {
    setIsExpanded(toggleValue);
    //fxAPIFetchTasksByObjectiveId(props.componentId);
    console.log('toggle: ' + toggleValue);
  }

  /* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  const handleProjectChange = (e) => {
    setNewProjectId(e.target.value);
  };

  const handleObjectiveNameChange = (e) => {
    setNewObjectiveName(e.target.value);
  };

  const handleObjectiveDescriptionChange = (e) => {
    setNewObjectiveDescription(e.target.value);
  };

  const handleCreateObjective = async (event) => {
    event.preventDefault();

    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const progress = '0';
    const relatedProject = newProjectId;
    const name = newObjectiveName;
    const description = newObjectiveDescription;

    try {
      await createObjective(name, description, relatedOrg, relatedProject, progress);
      console.log('Objective Created successfully!');
    } catch (error) {
      console.error('Error creating objective:', error);

      if (error.message.includes('Parse Error')) {
        console.error('Specific Parse Error occurred:', error);
      }
    } finally {
      // Ensure that the parent is refreshed, regardless of the error
      props.refreshParent();
    }
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchProjectsByOrgId(currentOrgId);
  }, []);

  return (
      <div>
          <div className='fx-comp-quick-create-objective-form-header'>
            <div>
                {!isExpanded &&
                  <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(true)}><ExpandMoreIcon /></button>
                  }
                  {isExpanded &&
                  <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(false)}><ExpandLessIcon /></button>
                }
            </div>
            <div>
              Objective Quick Create for {props.relatedProject}
            </div>
          </div>
          {isExpanded && (
              <Box p={3} className="fx-">
                <form className='fx-comp-quick-create-objective-form' onSubmit={handleCreateObjective}>
                  <div className='fx-comp-quick-create-objective-fields-row'>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Related Project</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={newProjectId}
                          label="Related Project"
                          onChange={handleProjectChange}
                          className='fx-comp-quick-create-objective-related-project-field'
                        >
                          {projectsByOrgId.map((projectItem) => (
                            <MenuItem value={projectItem.project_id}>{projectItem.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <TextField id="outlined-basic" label="Title" variant="outlined" className='fx-comp-quick-create-objective-related-title-field' onChange={handleObjectiveNameChange} />
                    </div>
                    <div className="fx-comp-quick-create-objective-text-area-description">
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Description"
                      multiline
                      maxRows={4}
                      fullWidth
                      onChange={handleObjectiveDescriptionChange}
                      className='fx-comp-quick-create-objective-related-description-field'
                      />  
                    </div>
                    <div>
                      <Button variant='outlined' type="submit">Create Objective</Button>   
                    </div>  
                  </div>
                </form>
              </Box>
          )}
        </div>

  );
};

export default CompQuickCreateObjective;