import axios from 'axios';

const getReportsByOrgId = async (orgId, setError, setReportsData) => {
    setError(null);
    setReportsData([]); // Reset user personas data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching reports data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/reports/reports-by-org', {
        params: { orgId }
      });
      console.log('API response received1:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting reports data 1:', items);
      setReportsData(items); // Correctly update the state with received data
      console.log('Updated reports data 1:', items);
    } catch (error) {
      console.error('Error fetching reports data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch reports 1 data');
    }
};

export { getReportsByOrgId };