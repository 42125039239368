import axios from 'axios';

const getProjectsByOrgId = async (orgId, setError, setProjectsData) => {
    setError(null);
    setProjectsData([]); // Reset projects data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching project data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/projects/projects-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting projects data:', items);
      setProjectsData(items); // Correctly update the state with received data
      console.log('Updated project data:', items);
    } catch (error) {
      console.error('Error fetching projects data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch projects data');
    }
};

const createProject = async (name, description, related_org, setProjectResponse) => {
  try {
    // Generate projectId
    const projectId = generateUUID();

    // Simple validation
    if (!projectId || !name || !description || !related_org) {
      console.warn('Missing required parameters: projectId, name, description, or related_org.');
      return;
    }

    console.log(`Creating project for orgId: ${related_org}, projectName: ${name}`);

    // Send request
    const response = await axios.post('/fxapi/projects/create', {
      project_id: projectId,
      name,
      description,
      related_org,
    });

    console.log('API response received:', response.data);

    // Update state with response data
    setProjectResponse(response.data);

  } catch (error) {
    // Suppress the error, just log it quietly
    console.error('Error creating project:', error);
    // Optionally, handle specific errors here without alerting the user
  }
};

const updateProject  = async (projectId, name, description, related_org) => {

  try {
    const response = await axios.put(`/fxapi/projects/update/${projectId}`, {
      project_id: projectId,
      name: name,
      description: description,
      related_org: related_org
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};


const deleteProject = async (projectId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!projectId) {
    setError('Project ID parameter is required');
    return;
  }

  try {
    console.log(`Deleting project with ID: ${projectId}`);
    const response = await axios.delete(`/fxapi/projects/delete/${projectId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted project with ID: ${projectId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting project:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete project';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

// Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export { getProjectsByOrgId, createProject, deleteProject, updateProject };