import React from 'react';
import './CompComponentList.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const CompComponentList = (props) => {

  return (
    <ul>
      <h4>{props.listName}</h4>
      {props.children}
    </ul>
  );
};

export default CompComponentList;