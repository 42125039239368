import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CompHeader from './Components/CompHeader'
import PageHome from './Pages/PageHome';
import PageOnboardingWizard from './Pages/PageOnboardingWizard';
import PageAuthCallback from './Pages/PageAuthCallback';
import PageLoginAlt from './Pages/PageLoginAlt';
import PageStart from './Pages/PageStart';
import PageDashboard from './Pages/PageDashboard';
import PageReportsUXAudit from './Pages/PageReportsUXAudit';
import PageProfileUser from './Pages/PageProfileUser';
import PageUIAssetDetail from './Pages/PageUIAssetDetail';
import PageIntegrations from './Pages/PageIntegrations';
import PageComponents from './Pages/PageComponents';
import PageObjectLinks from './Pages/PageObjectLinks';
import PageIntegrationTest from './Pages/PageIntegrationTest';
import AuthMSTeamsCallback from './Components/AuthMSTeamsCallback';
import PageSubjectiveTemplates from './Pages/PageSubjectiveTemplates';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/" element={<Start/>}/>
          <Route exact path="/callback" element={<AuthCallback/>}/>
          <Route exact path="/onboarding-wizard" element={<OnboardingWizard/>}/>
          <Route exact path="/login-alt" element={<LoginAlt/>}/>
          <Route exact path="/dashboard/:type/:compId" element={<Dashboard/>}/>
          <Route exact path="/reports/ux-audit" element={<ReportsUXAudit/>}/>
          <Route exact path="/profile-user" element={<ProfileUser/>}/>
          <Route exact path="/ui-asset-detail/:compId" element={<UIAssetDetail/>}/>
          <Route exact path="/integrations/" element={<Integrations/>}/>
          <Route exact path="/components/" element={<Components/>}/>
          <Route exact path="/object-links/" element={<ObjectLinks/>}/>
          <Route exact path="/integration-test/" element={<IntegrationTest/>}/>
          <Route exact path="/auth/callback" element={<AuthMSTeamsCallback />}/>
          <Route exact path="/subjective-templates" element={<SubjectiveTemplates />}/>   
        </Routes>
      </Router>
    </div>
    
  );
}

function Home() {
  return (
    <PageHome />
  );
}

function Start() {
  return (
    <PageStart />
  );
}

function OnboardingWizard() {
  return (
    <PageOnboardingWizard />
  );
}

function AuthCallback() {
  return (
    <PageAuthCallback />
  );
}

function LoginAlt() {
  return (
    <PageLoginAlt />
  );
}

function Dashboard() {
  return (
    <PageDashboard />
  );
}

function ReportsUXAudit() {
  return (
    <PageReportsUXAudit />
  );
}

function ProfileUser() {
  return (
    <PageProfileUser />
  );
}

function UIAssetDetail() {
  return (
    <PageUIAssetDetail />
  );
}

function Integrations() {
  return (
    <PageIntegrations />
  );
}

function Components() {
  return (
    <PageComponents />
  );
}

function ObjectLinks() {
  return (
    <PageObjectLinks />
  );
}

function IntegrationTest() {
  return (
    <PageIntegrationTest />
  );
}

function SubjectiveTemplates() {
  return (
    <PageSubjectiveTemplates />
  );
}



export default App;
