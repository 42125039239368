import React, { useState } from 'react';
import './CompStartSetup.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CompPageTempIntroSlide from './CompPageTempIntroSlide';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Chip from '@mui/material/Chip';

const CompStartSetup = (props) => {

  const [gettingStartedCurrentStep, setGettingStartedCurrentStep] = useState('create-organization');
  const [newOrganization, setNewOrganization] = useState(null);
  const [newVisionStatement, setNewVisionStatement] = useState(null);
  const [newObjective, setNewObjective] = useState(null);
  const [newSubjective, setNewSubjective] = useState(null);
  const [newSubjectiveDescription, setNewSubjectiveDescription] = useState(null);
  const [newTeamMember, setNewTeamMember] = useState(null);

  const handleChangeStep = (currentStepName, advanceStepName) => {
    console.log('completed: ' + currentStepName);
    setGettingStartedCurrentStep(advanceStepName);
  };

  const handleChangeOrganization = (event) => {
    setNewOrganization(event.target.value);
    console.log('change organization: ' + newOrganization);
  }

  const handleChangeVisionStatement = (event) => {
    setNewVisionStatement(event.target.value);
    console.log('change vision statement: ' + newVisionStatement);
  }

  const handleChangeObjective = (event) => {
    setNewObjective(event.target.value);
    console.log('change objective: ' + newObjective);
  }

  const handleChangeSubjective = (event) => {
    setNewSubjective(event.target.value);
    console.log('change subjective: ' + newSubjective);
  }

  const handleChangeSubjectiveDescription = (event) => {
    setNewSubjectiveDescription(event.target.value);
    console.log('change subjective: ' + newSubjective);
  }

  const handleChangeTeamMember = (event) => {
    setNewTeamMember(event.target.value);
  }

  const entryStepOrganizationLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">Welcome to Fluxity
        <br /><br />
        Your single source of truth for effortless product creation. Streamline, innovate, and bring your ideas to life with clarity and precision, without interruption.</div>
      </div>
    </>
  );

  const entryStepOrganizationRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div>What is your organization called</div>
        <div className="fx-comp-startsetup-step-vision-statement-wrap">
          <TextField
            fullWidth
            id="organization"
            label="Organization"
            value={newOrganization}
            onChange={handleChangeOrganization}
          />
        </div>
        <div className="fx-comp-startsetup-step-action-button-row-right-only">
          <Button variant="contained" onClick={() => handleChangeStep('create-organization', 'vision-statement')}>Next</Button>
        </div>
        
      </div>
    </>
  );

  const stepOneVisionStatementLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">Defining a Vision Statement
        <br /><br />
        Begin by identifying the core purpose of your project or organization. Ask yourself what the ultimate impact you want to have is, both for your customers and within your industry. Focus on the long-term aspirations and the bigger picture, looking beyond immediate goals to consider the broader change you want to create</div>
      </div>
    </>
  );

  const stepOneVisionStatementRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div>Our vision is to [core purpose or impact] by [how you plan to achieve it], ultimately [long-term goal or aspiration] for [target audience or industry].”</div>
        <div className="fx-comp-startsetup-step-vision-statement-wrap">
          <TextField
            fullWidth
            id="vision-statement"
            label="Vision Statement"
            multiline
            maxRows={4}
            value={newVisionStatement}
            onChange={handleChangeVisionStatement}
          />
        </div>
        <div className="fx-comp-startsetup-step-action-button-full-row">
          <div className="fx-comp-startsetup-step-action-left-button">
            <Button variant="text" onClick={() => handleChangeStep('vision-statement', 'create-organization')}>
              Back
            </Button>
          </div>
          <div className="fx-comp-startsetup-step-action-right-buttons">
            <Button variant="contained" onClick={() => handleChangeStep('vision-statement', 'create-objective')}>
              Next
            </Button>
          </div>
        </div>
        
      </div>
    </>
  );

  const stepTwoCreateObjectiveLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">Defining an Objective
        <br /><br />
        An Objective is a clear and focused statement that defines the primary goal and expected outcomes for a specific screen or feature within an application. It outlines the purpose of the screen, what success looks like, and what actions users should complete. </div>
      </div>
    </>
  );

  const stepTwoCreateObjectiveRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div>Start by thinking about the primary function of the screen. What is the main task users need to complete? By setting a clear objective, you ensure the design and functionality of the screen aligns with its intended purpose</div>
        <div className="fx-comp-startsetup-step-objective-wrap">
          <TextField
              fullWidth
              id="objective"
              label="Objective"
              multiline
              maxRows={4}
              value={newObjective}
              onChange={handleChangeObjective}
            />
        </div>
        <div><strong>Clearly summarize your objective, focusing on the main goal and outcomes.</strong></div>
        
        <div className="fx-comp-startsetup-step-action-button-full-row">
          <div className="fx-comp-startsetup-step-action-left-button">
            <Button variant="text" onClick={() => handleChangeStep('create-objective', 'vision-statement')}>
              Back
            </Button>
          </div>
          <div className="fx-comp-startsetup-step-action-right-buttons">
            <Button variant="text" onClick={() => handleChangeStep('create-objective', 'create-subjective')}>
              Skip
            </Button>
            <Button variant="contained" onClick={() => handleChangeStep('create-objective', 'create-subjective')}>
              Next
            </Button>
          </div>
        </div>
        
      </div>
    </>
  );

  const stepThreeCreateSubjectiveLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">Creating Subjectives
        <br /><br />
        A Subjective is a task or action that helps achieve the overall Objective. It breaks down the larger goal into smaller, actionable steps. Each Subjective is assigned to a specific role responsible for completing the task.</div>
      </div>
    </>
  );

  const stepThreeCreateSubjectiveRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div>Define the Task: Clearly describe what needs to be done, the expected result, and how it contributes to the overall Objective</div>
        <br />
        <div>
          <strong>Objective</strong>
        </div>
        <div>
          {newObjective}
        </div>
        <br />
        <div className="fx-comp-startsetup-step-objective-wrap">
          <TextField
              fullWidth
              id="subjective"
              label="Subjective"
              multiline
              maxRows={4}
              value={newSubjective}
              onChange={handleChangeSubjective}
            />
         </div>
         <div className="fx-comp-startsetup-step-objective-wrap">
            <TextField
              fullWidth
              id="subjective-description"
              label="Subjective Description"
              multiline
              maxRows={4}
              value={newSubjectiveDescription}
              onChange={handleChangeSubjectiveDescription}
            />
        </div>
        <div><strong>Clearly summarize your objective, focusing on the main goal and outcomes.</strong></div>
        
        <div className="fx-comp-startsetup-step-action-button-full-row">
          <div className="fx-comp-startsetup-step-action-left-button">
            <Button variant="text" onClick={() => handleChangeStep('create-subjective', 'create-objective')}>
              Back
            </Button>
          </div>
          <div className="fx-comp-startsetup-step-action-right-buttons">
            <Button variant="text" onClick={() => handleChangeStep('create-subjective', 'action-tracker')}>
              Skip
            </Button>
            <Button variant="contained" onClick={() => handleChangeStep('create-subjective', 'action-tracker')}>
              Next
            </Button>
          </div>
        </div>
        
      </div>
    </>
  );

  const stepFourActionTrackerLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">The Action Tracker
        <br /><br />
        Estimate, monitor and manage tasks assigned to team members within a project. 
        <br /><br />
        Enabling users to track progress by assigning due dates, updating statuses, and monitoring deadlines set by the individuals responsible for the work—all while keeping you informed without constant interruptions
        </div>
      </div>
    </>
  );

  const stepFourActionTrackerRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div>The Action Tracker ensures accountability, maintains visibility into task progress, and keeps the project on schedule. It provides an at-a-glance view of each task’s time remaining and current status, making it easier to manage team efforts with the ability to check status updates at any time.</div>
        <br />
        <div className="fx-comp-startsetup-step-team-member-row">
          <div className="fx-comp-startsetup-step-team-member-row-item-1">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Team Member</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newTeamMember}
              label="Team Member"
              onChange={handleChangeTeamMember}
            >
              <MenuItem value={10}>User 1</MenuItem>
              <MenuItem value={20}>User 2</MenuItem>
              <MenuItem value={30}>User 3</MenuItem>
            </Select>
          </FormControl>
          </div>
          {/*
          <div className="fx-comp-startsetup-step-team-member-row-item-2">
            <Button variant="contained">
              Send
            </Button>
          </div>
           */}
        </div>
        <br />
        <div>
          <strong>Subjective</strong>
        </div>
        <div>
          {newSubjective}
        </div>
        <br />
        <div className="fx-comp-startsetup-step-team-member-row">
          <div className="fx-comp-startsetup-step-team-member-row-item-1">
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div>Will complete by</div>
              <DatePicker />
            </LocalizationProvider>
          </FormControl>
          </div>
          <div className="fx-comp-startsetup-step-team-member-row-item-2">
            <Button variant="contained">
              Send
            </Button>
          </div>
        </div>
        <div className="fx-comp-startsetup-step-action-button-full-row">
          <div className="fx-comp-startsetup-step-action-left-button">
            <Button variant="text" onClick={() => handleChangeStep('action-tracker', 'create-subjective')}>
              Back
            </Button>
          </div>
          <div className="fx-comp-startsetup-step-action-right-buttons">
            <Button variant="text" onClick={() => handleChangeStep('action-tracker', 'status-appeal')}>
              Skip
            </Button>
            <Button variant="contained" onClick={() => handleChangeStep('action-tracker', 'status-appeal')}>
              Next
            </Button>
          </div>
        </div>
        
      </div>
    </>
  );

  const stepFiveStatusAppealLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">Status Appeal
        <br /><br />
        This system enables real-time status updates and feedback through a direct, rating-style mechanism, reducing the need for constant follow-ups or meetings. The limited choices help convey the necessary information efficiently, ensuring swift decision-making and project alignment without disrupting productivity.
        </div>
      </div>
    </>
  );

  const stepFiveStatusAppealRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div>Enabling real-time status updates and feedback through a direct, rating-style mechanism, reducing the need for constant follow-ups or meetings. The limited choices help convey the necessary information efficiently, ensuring swift decision-making and project alignment without disrupting productivity</div>
        <br /><br />
        <div><strong>Subjective</strong></div>
        <div>{newSubjective}</div>
        <div className="fx-comp-startsetup-status-appeal-chip-row">
          <Chip label="Get Status" />
        </div>
        <br />
        <div>
          <Chip label="Status Reply" />
        </div>
        <div>
          <ul className="fx-comp-startsetup-status-appeal-response-desc">
            <li>Assigned to: Jane Smith (Developer)</li>
            <li>Status Appeal Response: In Progress</li>
            <li>Summary: Jane is actively working on the new authentication flow, but a full implementation is pending a security review scheduled for tomorrow.</li>
          </ul>
        </div>
        <br />
        <div className="fx-comp-startsetup-step-action-button-full-row">
          <div className="fx-comp-startsetup-step-action-left-button">
            <Button variant="text" onClick={() => handleChangeStep('status-appeal', 'action-tracker')}>
              Back
            </Button>
          </div>
          <div className="fx-comp-startsetup-step-action-right-buttons">
            <Button variant="text" onClick={() => handleChangeStep('status-appeal', 'referee')}>
              Skip
            </Button>
            <Button variant="contained" onClick={() => handleChangeStep('status-appeal', 'referee')}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  const stepSixRefereeLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">The Referee
        <br /><br />
        The integrated validation system within Fluxity designed to ensure your project meets critical quality standards across multiple key areas. 
        <br /><br />
        By providing real-time feedback and AI-driven suggestions, The Referee helps maintain best practices, enhance accessibility, optimize code, manage notifications, and generate actionable insights based on analytics
        </div>
      </div>
    </>
  );

  const stepSixRefereeRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div>Confidently create products that not only meet industry standards but also deliver exceptional user experiences by leveraging AI-Driven Suggestions: Offers intelligent recommendations for improving user experience, code efficiency, and overall project outcomes based on real-time data.</div>
        <br />
        <div>
        Accessibility: Ensures compliance with accessibility guidelines (e.g., WCAG) to make sure your product is usable by people of all abilities.
        <ul className="fx-comp-startsetup-status-appeal-response-desc">
          <li>Coding Standards: Validates that your code adheres to industry standards, promoting consistency, readability, and long-term maintainability.</li>
          <li>Notifications: Manages system and user notifications, ensuring they are timely, relevant, and not overwhelming.</li>
          <li>Analytics: Provides insights into user interactions and system performance to guide data-driven decisions.</li>
        </ul>
        </div>
        <br /><br />
        <div>
          <strong>Coding Standards Validation</strong>
        </div>
        <div>
          ...
        </div>
        <br />
        <div>
          <strong>Referee Response</strong>
        </div>
        <div>
          ...
        </div>
        <div className="fx-comp-startsetup-step-action-button-full-row">
          <div className="fx-comp-startsetup-step-action-left-button">
            <Button variant="text" onClick={() => handleChangeStep('referee', 'status-appeal')}>
              Back
            </Button>
          </div>
          <div className="fx-comp-startsetup-step-action-right-buttons">
            <Button variant="text" onClick={() => handleChangeStep('referee', 'fluxity')}>
              Skip
            </Button>
            <Button variant="contained" onClick={() => handleChangeStep('referee', 'fluxity')}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );


  return (
    <div>
      {gettingStartedCurrentStep === "" || gettingStartedCurrentStep === "create-organization" &&
        <CompPageTempIntroSlide headingOne="Create an Organization" panelLeftContent={entryStepOrganizationLeftPanel} panelRightContent={entryStepOrganizationRightPanel} />
      }
      {gettingStartedCurrentStep === "vision-statement" &&
        <CompPageTempIntroSlide headingOne="Vision Statement" panelLeftContent={stepOneVisionStatementLeftPanel} panelRightContent={stepOneVisionStatementRightPanel} />
      }
      {gettingStartedCurrentStep === "create-objective" &&
        <CompPageTempIntroSlide headingOne="Create an Objective" panelLeftContent={stepTwoCreateObjectiveLeftPanel} panelRightContent={stepTwoCreateObjectiveRightPanel} />
      }
      {gettingStartedCurrentStep === "create-subjective" &&
        <CompPageTempIntroSlide headingOne="Create a Subjective" panelLeftContent={stepThreeCreateSubjectiveLeftPanel} panelRightContent={stepThreeCreateSubjectiveRightPanel} />
      }
      {gettingStartedCurrentStep === "action-tracker" &&
        <CompPageTempIntroSlide headingOne="Add it to the Action Tracker" panelLeftContent={stepFourActionTrackerLeftPanel} panelRightContent={stepFourActionTrackerRightPanel} />
      }
      {gettingStartedCurrentStep === "status-appeal" &&
        <CompPageTempIntroSlide headingOne="Get Status Appeal" panelLeftContent={stepFiveStatusAppealLeftPanel} panelRightContent={stepFiveStatusAppealRightPanel} />
      }
      {gettingStartedCurrentStep === "referee" &&
        <CompPageTempIntroSlide headingOne="The Referee" panelLeftContent={stepSixRefereeLeftPanel} panelRightContent={stepSixRefereeRightPanel} />
      }
      
    </div>
  );
};

export default CompStartSetup;