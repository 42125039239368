import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CompPageTempUtilityCenter from '../Components/CompPageTempUtilityCenter';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import './PageStart.css';
import { useAuth0 } from "@auth0/auth0-react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import CompStartSetup from '../Components/CompStartSetup';
//import { authenticate } from '../Services/authService';
import CompPageTempIntroSlide from '../Components/CompPageTempIntroSlide';
import CompStartAuthenticated from '../Components/CompStartAuthenticated';

const PageStart = () => {

  const [userRole, setUserRole] = React.useState('');

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value);
  };

  const { isLoading, isAuthenticated, user, loginWithRedirect, logout } = useAuth0();


  const navigate = useNavigate();

    const handleClickRole = (userRole) => {
        // Process some logic here
        console.log('Processing logic...');
        localStorage.setItem("fxLocalUserRole", userRole);

        // After processing logic, navigate to the desired route
        navigate('/dashboard/role/' + userRole);
    };

/*
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  */

  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  const getUserByEmail = async (email) => {
    setError(null);
    setUserData(null);
  
    if (!email) {
      setError('Email parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching user data for email: ${email}`);
      const response = await axios.get('/fxapi/users/user-by-email', {
        params: { email }
      });
      setUserData(response.data);
      console.log('Found user data:', response.data);
      console.log('User Name: ' + response.data.items[0].username);
      localStorage.setItem("fxLocalUserName", response.data.items[0].username);
      console.log('User ID: ' + response.data.items[0].user_id);
      localStorage.setItem("fxLocalUserID", response.data.items[0].user_id);
      const storedLocalUserName = localStorage.getItem("fxLocalUserName");
      console.log('Local Username: ' + storedLocalUserName);
      const storedLocalUserID = localStorage.getItem("fxLocalUserID");
      console.log('Local User ID: ' + storedLocalUserID);

      /* manage related org */
      localStorage.setItem("fxLocalOrgID", response.data.items[0].related_org_id);
      const storedLocalOrgId = localStorage.getItem("fxLocalOrgID");
      console.log('Local Org ID: ' + storedLocalOrgId);

      /* manage default project id */
      localStorage.setItem("fxLocalDefaultProjectId", response.data.items[0].default_project_id);
      const storedLocalProjectId = localStorage.getItem("fxLocalDefaultProjectId");
      console.log('Local Default Project ID: ' + storedLocalProjectId);

      /* manage user first name */
      localStorage.setItem("fxLocalUserFirstName", response.data.items[0].first_name);
      const storedUserFirstName = localStorage.getItem("fxLocalUserFirstName");
      console.log('Local Default FirstName: ' + storedUserFirstName);

    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch user data');
    }
  };

  //getUserByEmail();


  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return <Button variant="contained" onClick={() => loginWithRedirect()}>Log In</Button>;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('old login');
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      console.log('user logged in: ');
      console.dir({ user });

      getUserByEmail(user.name);
    }
  }, []);

  const testVar = "test";

  const currentLocalOrgId = localStorage.getItem("fxLocalOrgID");

  function getHeadingOne() {
    if (isAuthenticated && user && currentLocalOrgId) {
      var userWelcomeMessage = "Choose Your Role";
    } else if(isAuthenticated && !currentLocalOrgId) {
      var userWelcomeMessage = "Continue Setup";
    } else {
      var userWelcomeMessage = "Please Login to Fluxity";
    }
    return userWelcomeMessage;
  }

  const headingOne = getHeadingOne();

  const scenarioLoggedOutLeftPanel = (
    <div>You're Logged Out</div>
  );
  
  const scenarioLoggedOutRightPanel = (
    <div>
      <div>Log In.</div>
      <LoginButton />
    </div>
  );

  

  return (
    <>
    {!isAuthenticated &&
            <div>
              <CompPageTempIntroSlide panelLeftContent={scenarioLoggedOutLeftPanel} panelRightContent={scenarioLoggedOutRightPanel} />
              {/*
              <div>
                <Alert severity="info">It looks like you're not logged in.  Login or Register.</Alert>
              </div>
              <div className="fx-global-form-button-row">
                <LoginButton />
              </div>
              */}
            </div>
    }
    {isAuthenticated &&
      <>
        {!currentLocalOrgId &&
          <CompStartSetup />
        }
        {currentLocalOrgId &&
          <CompStartAuthenticated />
        }
      </>
    }
{/*
    {isAuthenticated &&
    <CompPageTempUtilityCenter headingOne={headingOne} maxWidth="25">

      <Box sx={{ width: '100%' }}>


          <Box p={3} className="fx-page-auth-login-login-form-wrap">
            {!isAuthenticated &&
            <div>
              <div>
                <Alert severity="info">It looks like you're not logged in.  Login or Register.</Alert>
              </div>
              <div className="fx-global-form-button-row">
                <LoginButton />
              </div>
            </div>
            }
            {isAuthenticated &&
              <div>
                {currentLocalOrgId &&
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Choose a Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userRole}
                        label="User Role"
                        onChange={handleUserRoleChange}
                      >
                        <MenuItem value="c-level">Executive</MenuItem>
                        <MenuItem value="scrum-master">Scrum Master</MenuItem>
                        <MenuItem value="business-analyst">Business Analyst</MenuItem>
                        <MenuItem value="developer">Developer</MenuItem>
                        <MenuItem value="ux-designer">UX Designer</MenuItem>
                      </Select>
                    </FormControl>
                    
                    
                  </Grid>
                  <Grid item xs={3}>
                      <Button variant="contained" onClick={() => handleClickRole(userRole)}>Start</Button>
                  </Grid>
                </Grid>
                }
              </div>
            }
            
          </Box>

      </Box>
    </CompPageTempUtilityCenter>
          
}*/}
    
    </>
  );
};

export default PageStart;