import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import './CompObjectiveTable.css';
import { getViewObjectivesProjectsByOrgId } from '../fxAPI/fxObjectivesAPI.js';
import { getSubjectivesObjectivesByOrgId, getSubjectivesObjectivesByProjectId } from '../fxAPI/fxSubjectivesAPI.js';
import { DataGrid } from '@mui/x-data-grid';
import PageviewIcon from '@mui/icons-material/Pageview';
import { PanelContext } from '../Contexts/PanelContext';
import Slider from '@mui/material/Slider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompCRUDModal from './CompCRUDModal';
import EditIcon from '@mui/icons-material/Edit';
import CompUserAssignment from './CompUserAssignment.jsx';

const CompObjectiveTable = (props) => {

  const [tabValue, setTabValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);

  const openModal = (itemType, currentCRUDMethod, currentModalComponentData) => {
    setItemType(itemType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

    const [viewObjectivesProjectsByOrg, setViewObjectivesProjectsByOrgData] = useState([]); // Initialize as an array
  const [error, setError] = useState(null); // Handle errors

  // Fetch objectives by orgId
  const fxAPIFetchViewObjectivesProjectsByOrgId = async (orgId) => {
    try {
      await getViewObjectivesProjectsByOrgId(orgId, setError, setViewObjectivesProjectsByOrgData);
      console.log('Fetched objectives projects by orgId:', viewObjectivesProjectsByOrg);
    } catch (error) {
      console.error('Error fetching objectives:', error);
      setError(error);
    }
  };

  /* SUBJECTIVES */

  const [subjectivesObjectivesByOrgId, setSubjectivesObjectivesByOrgData] = useState([]); // Initialize as an array
  /* get subjective - objective by orgId */
  const fxAPIFetchSubjectivesObjectivesByOrgId = async (orgId) => {
    await getSubjectivesObjectivesByOrgId(orgId, setError, setSubjectivesObjectivesByOrgData);
    console.log('all subjectives by org Id');
    console.dir(subjectivesObjectivesByOrgId);
  };

  // Fetch data when component loads or when orgId changes
  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    if (currentOrgId) {
      fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
      fxAPIFetchSubjectivesObjectivesByOrgId(currentOrgId);
    }
  }, []); // Re-run the effect if orgId changes

  const { updatePanelContext } = useContext(PanelContext);

  const handleSwitchObjectiveContext = (objective_id, object) => {
    updatePanelContext("objective", objective_id, object);
  };

  const handleSwitchSubjectiveContext = (subjective_id, object) => {
    updatePanelContext("subjective", subjective_id, object);
  };
  
  const columnsObjectives = [
    { field: 'objective_id', headerName: 'ID', width: 30 },
    { field: 'objective_view_name', headerName: 'Objective', width: 300 },
    { field: 'project_view_name', headerName: 'Playbook', width: 250 },
    {
      field: 'daysLeft',
      headerName: 'Days Left',
      type: 'number',
      width: 100,
    },
    {
      field: 'progress',
      headerName: 'Progress',
      type: 'number',
      width: 100,
      renderCell: (params) => (
        <Slider defaultValue={params.progress} aria-label="Default" valueLabelDisplay="auto" />
      ),
    },
    {
      field: 'open',
      headerName: 'View',
      width: 100,
      renderCell: (params) => (
        <a
          href="#"
          onClick={() => handleSwitchObjectiveContext(params.row.objective_id, params.row)} // Use objective_id from the row data
          style={{ color: '#aaa', cursor: 'pointer' }}
        >
          <PageviewIcon />
        </a>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 100,
      renderCell: (params) => (
        <a
          href="#"
          onClick={() => openModal("objective", "view-edit", params.row)} // Use objective_id from the row data
          style={{ color: '#aaa', cursor: 'pointer' }}
        >
          <EditIcon />
        </a>
      ),
    },
  ];
  
  // Map the API data to the objective rows
  const rowsObjectives = viewObjectivesProjectsByOrg.map((item, index) => ({
    id: item.objective_id || index, // Use subjective_id as the ID for each row
    objective_view_name: item.objective_view_name || 'N/A', // Replace 'objective_view_name' with the actual field from your data
    objective_view_description: item.objective_view_description || 'N/A',
    project_view_name: item.project_view_name || 'N/A', // Replace 'project_view_name' with the actual field
    progress: item.progress,
    daysLeft: item.days_left || 0, // Replace 'days_left' with actual field
    objective_id: item.objective_id // Ensure subjective_id is available in row data
  }));

  const columnsSubjectives = [
    { field: 'subjective_id', headerName: 'ID', width: 30 },
    { field: 'subjective_view_name', headerName: 'Subjective', width: 300 },
    {
      field: 'date_created',
      headerName: 'Date Created',
      width: 125,
    },
    {
      field: 'date_updated',
      headerName: 'Date Updated',
      width: 125,
    },
    {
      field: 'user_assigned',
      headerName: 'Assigned To',
      width: 125,
      renderCell: (params) => (
        <div><CompUserAssignment userAssigned={params.row.user_assigned} componentType="subjective" componentData={params.row} /></div>
      ),
    },
    {
      field: 'open',
      headerName: 'View',
      width: 100,
      renderCell: (params) => (
        <a
          href="#"
          onClick={() => handleSwitchSubjectiveContext(params.row.subjective_id, params.row)} // Use subjective_id from the row data
          style={{ color: '#aaa', cursor: 'pointer' }}
        >
          <PageviewIcon />
        </a>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 100,
      renderCell: (params) => (
        <a
          href="#"
          onClick={() => openModal("subjective", "view-edit", params.row)} // Use subjective_id from the row data
          style={{ color: '#aaa', cursor: 'pointer' }}
        >
          <EditIcon />
        </a>
      ),
    },
  ];
  
  // Map the API data to the objective rows
  const rowsSubjectives = subjectivesObjectivesByOrgId.map((item, index) => ({
    id: item.subjective_id || index, // Use subjective_id as the ID for each row
    subjective_view_name: item.subjective_view_name || 'N/A', // Replace 'objective_view_name' with the actual field from your data
    subjective_view_description: item.subjective_view_description || 'N/A',
    date_created: item.date_created || 'N/A', // Replace 'project_view_name' with the actual field
    date_updated: item.date_updated,
    user_assigned: item.subjective_view_user_assigned || 0, // Replace 'days_left' with actual field
    subjective_id: item.subjective_id // Ensure subjective_id is available in row data
  }));
  
  const paginationModel = { page: 0, pageSize: 5 };

    

  return (
    <>
    <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={actionModalTitle} itemType={itemType} crudMethod={crudMethod} modalComponentData={modalComponentData}></CompCRUDModal>
    <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="All Objectives" />
              <Tab label="All Subjectives" />
            </Tabs>
    {tabValue === 0 && (
    <div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rowsObjectives}
          columns={columnsObjectives}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </div>
    )}
    {tabValue === 1 && (
    <div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rowsSubjectives}
          columns={columnsSubjectives}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </div>
    )}
    </>
  );
}

export default CompObjectiveTable;