import React, { useEffect, useState } from 'react';
import './CompComponentListItem.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Avatar1Img from '../images/avatar1.jpeg';
import Avatar2Img from '../images/avatar2.jpeg';
import Avatar3Img from '../images/avatar3.jpeg';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CompCRUDModal from './CompCRUDModal';
import { getTasksByObjectiveId, getTasksByUserPersonaId } from '../fxAPI/fxTasksAPI.js';
import { getComStatusByObjectiveId, getComStatusByUserPersonaId } from '../fxAPI/fxStatusAPI.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import CompMiniAvatar from './CompMiniAvatar.jsx';
import CompUserPersonaDetail from './CompUserPersonaDetail.jsx';


const CompComponentListItem = (props) => {

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  function handleToggleItem(toggleValue) {
    setIsExpanded(toggleValue);
    //fxAPIFetchTasksByObjectiveId(props.componentId);
    console.log('toggle: ' + toggleValue);
  }

  const [error, setError] = useState(null);

  const [tasksByObjectiveId, setTasksByObjectiveData] = useState([]); // Initialize as an array
  /* get tasks by objectiveId */
  const fxAPIFetchTasksByObjectiveId = async (objectiveId) => {
    await getTasksByObjectiveId(objectiveId, setError, setTasksByObjectiveData);
  };

  const [statusByObjectiveId, setComStatusByObjectiveData] = useState([]); // Initialize as an array
  /* get status by objectiveId */
  const fxAPIFetchStatusByObjectiveId = async (objectiveId) => {
    await getComStatusByObjectiveId(objectiveId, setError, setComStatusByObjectiveData);
  };

  const [statusByUserPersonaId, setComStatusByUserPersonaData] = useState([]); // Initialize as an array
  /* get status by userPersonaId */
  const fxAPIFetchStatusByUserPersonaId = async (userPersonaId) => {
    await getComStatusByUserPersonaId(userPersonaId, setError, setComStatusByUserPersonaData);
  };

  const [tasksByUserPersonaId, setTasksByUserPersonaData] = useState([]); // Initialize as an array
  /* get tasks by userPersonaId */
  const fxAPIFetchTasksByUserPersonaId = async (userPersonaId) => {
    await getTasksByUserPersonaId(userPersonaId, setError, setTasksByUserPersonaData);
  };

  if(props.itemType === "objective" || props.itemType === "project") {
    console.log('test passing component item data: ');
      console.dir(props.componentData);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState(false);
  const [modalItemType, setModalItemType] = useState(props.itemType);

      const openModal = (currentModalMode, currentModalItemType) => {
        setModalMode(currentModalMode);
        setModalItemType(currentModalItemType);
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };

  function checkStatusLabel() {
    if(props.taskStatus === "not-started") {
      var currentTaskStatusVal = "Not Started";
    } else if(props.taskStatus === "in-progress") {
      var currentTaskStatusVal = "In Progress";
    } else if(props.taskStatus === "complete") {
      var currentTaskStatusVal = "Complete";
    }

    return currentTaskStatusVal;
  }

  function checkStatusColor() {
    if(props.taskStatus === "not-started") {
      var currentTaskStatusColor = "error";
    } else if(props.taskStatus === "in-progress") {
      var currentTaskStatusColor = "warning";
    } else if(props.taskStatus === "complete") {
      var currentTaskStatusColor = "success";
    }

    return currentTaskStatusColor;
  }

  const currentTaskStatus = checkStatusLabel();
  const currentTaskStatusColor = checkStatusColor();

  // Menu Button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getModalTitle() {
    if(modalItemType === "project") {
      var compType = "Project";
    } else if(modalItemType === "objective") {
      var compType = "Objective";
    } else if(modalItemType === "user" || modalItemType === "user-persona") {
      var compType = "User";
    } else if(modalItemType === "ui-asset") {
      var compType = "UI Asset";
    } else if(modalItemType === "task") {
      var compType = "Task";
    } else if(modalItemType === "team-member") {
      var compType = "Team Member";
    } else if(modalItemType === "status") {
      var compType = "Status";
    }
    return compType;
  }

    const [relatedComponentId, setRelatedComponentId] = useState("");

    useEffect(() => {
      
      if (props.itemType === "objective") {
        const currentObjectiveId = props.componentData.objective_id;
        console.log('found objective in effect with id: ' + currentObjectiveId);
        console.log('test attempt at objective pull');
        fxAPIFetchTasksByObjectiveId(currentObjectiveId);
        fxAPIFetchStatusByObjectiveId(currentObjectiveId);
        console.log('passed through objective id: ' + currentObjectiveId);
        console.dir(tasksByObjectiveId);
        setRelatedComponentId(currentObjectiveId);
      } else if(props.itemType === "user-persona") {
        const currentUserPersonaId = props.componentId;
        console.log('found user persona in effect with id: ' + currentUserPersonaId);
        console.log('test attempt at user persona pull');
        fxAPIFetchStatusByUserPersonaId(currentUserPersonaId);
        fxAPIFetchTasksByUserPersonaId(currentUserPersonaId);
        console.log('status by persona test: ');
        console.dir(statusByUserPersonaId);
        //fxAPIFetchTasksByObjectiveId(currentObjectiveId);
        //fxAPIFetchStatusByObjectiveId(currentObjectiveId);
        console.log('passed through user persona id: ' + currentUserPersonaId);
        //console.dir(tasksByObjectiveId);
        setRelatedComponentId(props.componentId);
      } else {
        setRelatedComponentId(props.componentId);
      }
    }, [props.itemType, props.componentId]);
  

  useEffect(() => {
    console.log('tasksByObjectiveId updated:');
    console.dir(tasksByObjectiveId);
  }, [tasksByObjectiveId]);

  const modalTitleCompPortion = getModalTitle();


  return (
    <>
    <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={modalTitleCompPortion + ": " + props.itemLabel} modalComponentData={props.componentData} itemType={modalItemType} crudMethod={modalMode} relatedComponentId={relatedComponentId}></CompCRUDModal>
    
    <li className="fx-comp-component-list-item-li">
      <div className="fx-comp-component-list-item-row fx-comp-component-list-item-outer">
        <div className="fx-comp-component-list-item-row-items-left">
        {(props.itemType === "objective" || props.itemType === "user-persona") &&
            <>
            {!isExpanded &&
            <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(true)}><ExpandMoreIcon /></button>
            }
            {isExpanded &&
            <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(false)}><ExpandLessIcon /></button>
            }
            </>
         }
        {props.itemType === "user" || props.itemType === "user-persona" &&
          <span className="fx-comp-component-list-item-user-feature-avatar-wrap"><Avatar alt="Remy Sharp" src={Avatar1Img} /></span>
        }
          
            {props.itemLabel}
            {props.taskStatus && 
              <span className="fx-comp-component-list-item-chip-wrap"><Chip label={currentTaskStatus} color={currentTaskStatusColor} /></span>
            }
            
        </div>
        <div className="fx-comp-component-list-item-row-items-right">
              {props.itemType === "objective" &&
              <span className="fx-comp-component-list-item-slider-wrap"><Slider defaultValue={props.itemProgress} aria-label="Default" valueLabelDisplay="auto" /></span>
              }
              {props.itemType === "task" &&
                <>             
                  <div className="fx-comp-component-list-item-row-items-avatars">
                    <Chip avatar={<Avatar>*</Avatar>} label={props.taskAssignee} />
                  </div>
                </>
              }
              <Button onClick={() => openModal("view-edit", props.itemType)}>
                <OpenInFullIcon />
              </Button>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant="outlined"
            >
              Actions
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {props.itemType === "objective" &&
              <MenuItem onClick={() => openModal("create", "status")} onBlur={handleClose}>Get Status</MenuItem>
              }
              <MenuItem onClick={() => openModal("delete", props.itemType)} onBlur={handleClose}>Delete</MenuItem>
            </Menu>
        </div>
      </div>
      {props.relatedComponentType != "none" && props.itemType === "objective" &&
        <div className="fx-comp-component-list-item-context"><span className="fx-comp-component-list-item-context-label">Project: </span><Chip label={props.objectiveProjectLabel} onClick={handleClick} /></div>
      }
      {props.relatedComponentType != "none" && props.itemType === "task" &&
        <div className="fx-comp-component-list-item-context">
          <span className="fx-comp-component-list-item-context-label">Project: </span><Chip label={props.taskProjectLabel} onClick={handleClick} />
        </div>
      }
    </li>

    {isExpanded && (
          <>
            <Tabs value={tabValue} onChange={handleTabChange}>
              {props.itemType === "objective" &&
                <Tab label="Subjectives" />
              }
              {props.itemType === "user-persona" &&
                <Tab label="Detail" />
              }
              {props.itemType === "objective" &&
                <Tab label="Status" />
              }
            </Tabs>
            {tabValue === 0 && (
              <Box p={3} className="fx-">
                {props.itemType === "objective" &&
                  <>
                  {tasksByObjectiveId.length > 0 ? (
                    tasksByObjectiveId.map((task) => (
                      <div key={task.task_id} className='fx-comp-component-list-item-subtask'>
                        {task.title}  <Chip label={task.progress} />
                      </div>
                    ))
                  ) : (
                    <Alert severity="info">No subjectives found for this objective</Alert>
                  )}
                  </>
                }
                {props.itemType === "user-persona" &&
                  <>
                    {/*<CompMiniAvatar userId={props.componentData.user_id} />*/}
                    <CompUserPersonaDetail />
                  </>
                }
            </Box>
          )}

          {tabValue === 1 && (
              <Box p={3} className="fx-">
                {props.itemType === "objective" &&
                  <>
                    {statusByObjectiveId.length > 0 ? (
                      statusByObjectiveId.map((status) => (
                        <div key={status.status_id} className='fx-comp-component-list-item-subtask'>
                          {status.title}  <Chip label={status.status_current} />
                        </div>
                      ))
                    ) : (
                      <Alert severity="info">No status found for this objective</Alert>
                    )}
                  </>
                }
                {props.itemType === "user-persona" &&
                  <>
                    {statusByUserPersonaId.length > 0 ? (
                      statusByUserPersonaId.map((status) => (
                        <div key={status.status_id} className='fx-comp-component-list-item-subtask'>
                          {status.title}  <Chip label={status.status_current} />
                        </div>
                      ))
                    ) : (
                      <Alert severity="info">No status found for this user persona</Alert>
                    )}
                  </>
                }
              </Box>
          )}
          </>
        )}
    </>
  );
};

export default CompComponentListItem;