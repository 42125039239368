import React, { useEffect, useState } from 'react';
import './CompCRUDFormTask.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createTask, updateTask } from '../fxAPI/fxTasksAPI.js';
import { useParams } from "react-router-dom";

const CompCRUDFormTask = (props) => {

  const params = useParams();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [taskResponse, setTaskResponse] = useState(null);



  console.log('current task data on form task: ');
  console.dir(props.currentComponentData);

  const handleCreateTask = (event) => {
    event.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    if(params.type === "projects") {
      var relatedCurrentComponentType = 'project';
    } else {
      var relatedCurrentComponentType = '';
    }
    const relatedCurrentComponent = params.compId;
    createTask(title, description, relatedOrg, relatedCurrentComponent, relatedCurrentComponentType, setError, setTaskResponse);
  };

  const [currentEditableState, setCurrentEditableState] = useState('view');

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleUpdateTask = async (e) => {
    console.log('ran update task: ' + props.currentComponentData.task_id);
    e.preventDefault();
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const taskId = props.currentComponentData.task_id;
    const title = 'Task Updated from API';
    const description = 'Updated Task Description from API';
    const related_org = '12345';
  
    await updateTask(taskId, title, description, related_org);
  };



  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
          Parameters from URL- Type: {params.type}, Component Id: {params.compId}
        <form onSubmit={handleCreateTask}>
        <br />
          <div>
            <TextField id="outlined-basic" label="Task Name" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <br />
          <div className="fx-global-form-text-area-description">
            <TextField
                id="outlined-multiline-flexible"
                label="Task Description"
                multiline
                maxRows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
            />  
          </div>
          
          <CompModalButtons>
                <Button type="submit">Create Task</Button>
              </CompModalButtons>  
        </form>

        {error && <div style={{ color: 'red' }}>{error}</div>}
        {taskResponse && (
          <div>
            <h3>Task Created Successfully:</h3>
            <pre>{JSON.stringify(taskResponse, null, 2)}</pre>
          </div>
        )}
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              
              <div>
                <div className='fx-global-form-read-only-label'>Task Title</div>
                <div>{props.currentComponentData.task_view_title}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Task Description</div>
                <div>{props.currentComponentData.task_view_description}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Related Project</div>
                <div>{props.currentComponentData.project_view_name}</div>
              </div> 
              
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateTask}>
                <div>
                  <TextField id="outlined-basic" label="Task Name" variant="outlined" />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Task Description"
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type="submit">Save Task</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormTask;