import * as React from 'react';
import { useState } from 'react';
import './PageOnboardingWizard.css';
import Box from '@mui/material/Box';
import CompPageTempUtilityCenter from '../Components/CompPageTempUtilityCenter';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CompCard from '../Components/CompCard';
import CompPageTempIntroSlide from '../Components/CompPageTempIntroSlide';

export default function PageOnboardingWizard() {

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    'Step 1: Company Information',
    'Step 2: Program Starting Point',
    'Step 3: Account Level'
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Box>
            <h2>Company Information</h2>
            <h3>Point of Contact</h3>
            <div className="fx-page-onboarding-wizard-row-names">
              <div><TextField label="First Name" fullWidth margin="normal" /></div>
              <div><TextField label="Last Name" fullWidth margin="normal" /></div>
            </div>
            <TextField label="Email" type="email" fullWidth margin="normal" />
            <h3>Company Contact</h3>
            <div>
              <div><TextField label="Address" fullWidth margin="normal" /></div>
            </div>
            <div className="fx-page-onboarding-wizard-row-citystatezip">
              <div className="fx-page-onboarding-wizard-row-citystatezip-item-1"><TextField label="City" fullWidth margin="normal" /></div>
              <div className="fx-page-onboarding-wizard-row-citystatezip-item-2">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="fx-page-onboarding-wizard-row-citystatezip-item-3"><TextField label="Zip" fullWidth margin="normal" /></div>
            </div>
          </Box>
        );
      case 1:
        return (
          <Box>
            <h2>Program Starting Point</h2>
            <h3>Start Your First Project (Application)</h3>
            <div><TextField label="Project Title" fullWidth margin="normal" helperText="Adding a project title will help us start your first workspace" /></div>
            <div><TextField label="First Objective" fullWidth margin="normal" helperText="Add an objective will help define a goal for that project" /></div>
          </Box>
        );
      case 2:
        return (
          <Box>
            <h3>Account Level</h3>
            <div className="fx-page-onboarding-wizard-row-accountlevel">
              <div className="fx-page-onboarding-wizard-row-accountlevel-item">
                <CompCard cardTitle="Basic" cardLinkTitle="Select" cardDescription="This is the basic or free level" cardImg="http://placehold.it/200x100">...</CompCard>
              </div>
              <div className="fx-page-onboarding-wizard-row-accountlevel-item">
                <CompCard cardTitle="Level 2" cardLinkTitle="Select" cardDescription="This is the next level (2) of subscription" cardImg="http://placehold.it/200x100">...</CompCard>
              </div>
              <div className="fx-page-onboarding-wizard-row-accountlevel-item">
                <CompCard cardTitle="Level 3" cardLinkTitle="Select" cardDescription="This is the next level (3) of subscription" cardImg="http://placehold.it/200x100">...</CompCard>
              </div>
              <div className="fx-page-onboarding-wizard-row-accountlevel-item">
                <CompCard cardTitle="Enterprise" cardLinkTitle="Select" cardDescription="This is the enterprise level of subscription" cardImg="http://placehold.it/200x100">...</CompCard>
              </div>
            </div>
          </Box>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <CompPageTempIntroSlide headingOne="Let's Get Started 1" maxWidth="40">
      Test
    </CompPageTempIntroSlide>
  );
}