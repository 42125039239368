import React, { useEffect, useState } from 'react';
import './PageReportsUXAudit.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompComponentList from '../Components/CompComponentList';
import CompComponentListItem from '../Components/CompComponentListItem';

export default function PageReportsUXAudit() {
  
  const welcomeMessage = "UX Audits";

  return (
    <CompPageTempMaster3Panel headingOne={welcomeMessage}>
      Reports - UX Audit
      <CompComponentList listName="Recent UX Audits">
        <CompComponentListItem itemLabel="Assessment - 07-12-2024" taskStatus="complete" />
        <CompComponentListItem itemLabel="Assessment - 06-12-2024" taskStatus="not-started" />
      </CompComponentList>
    </CompPageTempMaster3Panel>
  );
}