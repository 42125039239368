import React, { useEffect, useState } from 'react';
import './CompCRUDFormStatus.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { updateStatus } from '../fxAPI/fxStatusAPI';

const CompCRUDFormStatus = (props) => {

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [relatedComponentType, setRelatedComponentType] = useState('');
  const [relatedComponentId, setRelatedComponentId] = useState('');

  console.log('current status data on form status: ');
  console.dir(props.currentComponentData);

  useEffect(() => {
    if (props.currentComponentData?.objective_id) {
      setRelatedComponentType('objective');
      setRelatedComponentId(props.currentComponentData.objective_id);
    } else if(props.currentComponentData?.task_id) {
      setRelatedComponentType('task');
      setRelatedComponentId(props.currentComponentData.task_id);
    } else if(props.currentComponentData?.user_id) {
        setRelatedComponentType('user');
        setRelatedComponentId(props.currentComponentData.user_id);
    } else if(props.currentComponentData?.user_persona_id) {
        setRelatedComponentType('user-persona');
        setRelatedComponentId(props.currentComponentData.user_persona_id);
    }
  }, [props.currentComponentData]);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleUpdateStatus = async (e) => {
    console.log('ran update status: ' + props.currentComponentData.status_id);
    e.preventDefault();
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const statusId = props.currentComponentData.status_id;
    const title = 'Status Updated from API';
    const description = 'Updated Status Description from API';
    const related_org = '12345';
    const status_current = '';
    const resolved = '';
  
    await updateStatus(statusId, title, status_current, description, related_org, resolved);
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <div>Related Component Type: {relatedComponentType}</div>
            <div>Related Component Id: {relatedComponentId}</div>
            <br />
            <form>
              <div>
                Related to: 
              </div>
              <div>
                <TextField id="outlined-basic" label="Status On" variant="outlined" />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Status Description"
                multiline
                maxRows={4}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button>Create Status</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
        <div>
          <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
            
          </CompCRUDModalActionBar>
            <br />
            { currentEditableState === "edit" &&
            <form onSubmit={handleUpdateStatus}>
              <div>
                Related to: 
              </div>
              <div>
                <TextField id="outlined-basic" label="Status Title" variant="outlined" />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Status Description"
                multiline
                maxRows={4}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Save Status</Button>
              </CompModalButtons>         
            </form>
            }
            { currentEditableState === "view" &&
              <div>
                <div className='fx-crud-modal-status-view-row'>
                  <div>
                    <div className='fx-global-form-read-only-label'>Related to: </div>
                    <div>{props.currentComponentData.related_component}</div>
                    <div className='fx-global-form-read-only-label'>Status On: </div>
                    <div>{props.currentComponentData.related_component_type}</div>
                  </div>
                  <div>
                    <div className='fx-global-form-read-only-label'>Status Title: </div>
                    <div>{props.currentComponentData.status_view_title}</div>
                  </div> 
                  <div>
                    <div className='fx-global-form-read-only-label'>Status Progress: </div>
                    <div>{props.currentComponentData.status_current}</div>
                  </div>
                </div>
                <div>
                    <div className='fx-global-form-read-only-label'>Status Description</div>
                    <div>{props.currentComponentData.status_view_description}</div>
                </div>
              </div>
            }
        </div>
        }
    </div>
  );
};

export default CompCRUDFormStatus;