import React, { useState, createContext } from 'react';

// Create the context
export const PanelContext = createContext();

// Create the provider component
export const PanelProvider = ({ children }) => {
    const [panelContext, setPanelContext] = useState("Default context");
    const [panelObjectId, setPanelObjectId] = useState("#");
    const [panelObject, setPanelObject] = useState({});

    // Define the function to update the context
    const updatePanelContext = (contextType, objectId, object) => {
        console.log("Updating panel context in parent:", { contextType, objectId, object });
        setPanelContext(contextType);
        setPanelObjectId(objectId);
        setPanelObject(object);
    };

    // Provide the context values to children
    return (
        <PanelContext.Provider value={{ panelContext, panelObjectId, panelObject, updatePanelContext }}>
            {children}
        </PanelContext.Provider>
    );
};