import React, { useState, useEffect, useContext } from 'react';
import './CompSubjectivePanel.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Avatar from '@mui/material/Avatar';
import { getSubjectivesByObjectiveId } from '../fxAPI/fxSubjectivesAPI.js';
import CompQuickCreateSubjective from './CompQuickCreateSubjective.jsx';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PageviewIcon from '@mui/icons-material/Pageview';
import { PanelContext } from '../Contexts/PanelContext';
import CompUserAssignment from './CompUserAssignment.jsx';


const CompSubjectivePanel = (props) => {

  const [error, setError] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [subjectiveCreateIsExpanded, setSubjectiveCreateIsExpanded] = useState(false);

  const [subjectivesByObjective, setSubjectivesByObjectiveData] = useState([]); // Initialize as an array
  /* get subjective by objective id */
  const fxAPIFetchSubjectivesByObjectiveId = async (objectiveId) => {
    await getSubjectivesByObjectiveId(objectiveId, setError, setSubjectivesByObjectiveData);
    console.log('subjectives by objective Id');
    console.dir(subjectivesByObjective);
  };

  function handleToggleItem(toggleValue) {
    setIsExpanded(toggleValue);
    //fxAPIFetchTasksByObjectiveId(props.componentId);
    console.log('toggle: ' + toggleValue);
  }

  function handleToggleSubjectiveCreate(toggleValue) {
    setSubjectiveCreateIsExpanded(toggleValue);
    console.log('toggle subjective create: ' + toggleValue);
  }

  useEffect(() => {
    fxAPIFetchSubjectivesByObjectiveId(props.relatedObjective);
  }, []);

  useEffect(() => {
    // This will be called every time the 'refresh' prop changes
    // You can refresh the list here
    console.log('Refreshing list...');
    fxAPIFetchSubjectivesByObjectiveId(props.relatedObjective);
    // Example: Call an API or reset the list data
  }, [props.refresh]);

  const { updatePanelContext } = useContext(PanelContext);

  const handleSwitchContext = (subjective_id, object) => {
    updatePanelContext("subjective", subjective_id, object);
  };

  return (
    <div>
      <div className='fx-comp-subjective-panel-outer'>
          <div className='fx-comp-subjective-panel-header'>
              <div>
                {!isExpanded &&
                  <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(true)}><ExpandMoreIcon /></button>
                  }
                  {isExpanded &&
                  <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(false)}><ExpandLessIcon /></button>
                }
              </div>
              <div className='fx-comp-subjective-panel-header-content-items'>
                <div className='fx-comp-subjective-panel-header-content-item'>{props.panelName}</div>
              </div>
          </div>
          {isExpanded && (
          <div className='fx-comp-subjective-panel-content'>
            {subjectivesByObjective.map((subjective) => (
              <div className="fx-comp-subjective-panel-item-outer">
                <div className="fx-comp-subjective-panel-view-name">{subjective.name} </div>
                <div className="fx-comp-subjective-panel-item-right-actions">
                <div className="fx-comp-subjective-panel-view-icon"><CompUserAssignment userAssigned={subjective.user_assigned} componentType="subjective" componentData={subjective} /></div>
                <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchContext(subjective.subjective_id, subjective)}><PageviewIcon /></div>
                </div>
                
              </div>
            ))}

            <div className="fx-comp-subjective-panel-add-icon" onClick={() => handleToggleSubjectiveCreate(true)}><AddCircleOutlineIcon /> Add Subjective</div>
            {subjectiveCreateIsExpanded && (
                <>
                  <CompQuickCreateSubjective relatedObjective={props.relatedObjective} relatedProject={props.relatedProject} refreshParent={props.refreshParent} />
                </>
            )}

          </div>
          )}
      </div>
      
    </div>
  );
};

export default CompSubjectivePanel;