import React from 'react';
import './CompContentBlock.css';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types'; // Import PropTypes for prop type validation
import Box from '@mui/material/Box'; // Assuming you're using Material-UI for Box

const CompContentBlock = (props) => {
  return (
    <div className={`uxc-comp-content-block ${props.width} ${props.background}`}>
      {props.variant === "uxc-variant-read-only-block" && (
        <h3 className="uxc-comp-content-block-read-only-header">{props.headingThree}</h3>
      )}
      <Box sx={{ flexGrow: 1 }} className={`uxc-comp-content-block-box ${props.variant}`}>
        {props.variant !== "uxc-variant-read-only-block" && (
          <h3>{props.headingThree}</h3>
        )}
        {/* Use React.cloneElement to pass any additional props to children */}
        {React.Children.map(props.children, child => {
          // Only clone if it's a valid React element
          return React.isValidElement(child) ? React.cloneElement(child, { ...props }) : child;
        })}
      </Box>
    </div>
  );
};

// Define prop types for better validation and documentation
CompContentBlock.propTypes = {
  width: PropTypes.string,
  background: PropTypes.string,
  variant: PropTypes.string,
  headingThree: PropTypes.string.isRequired,
  children: PropTypes.node, // Allow any valid React node as children
};

export default CompContentBlock;