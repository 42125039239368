import React, { useState, useEffect } from 'react';
import './CompAnchorToolbar.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import EditIcon from '@mui/icons-material/Edit';
import CompUIAssetAnchorModal from './CompUIAssetAnchorModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { getObjectivesByProjectIdAndAnchorId } from '../fxAPI/fxObjectivesAPI.js';

const CompAnchorToolbar = (props) => {
  const [error, setError] = useState(null);

  /* OBJECTIVES BY PROJECT AND ANCHOR */
  const [objectivesByProjectAndAnchor, setObjectivesByProjectAndAnchorData] = useState([]);

  const fxAPIFetchObjectivesByProjectAndAnchorId = async (projectId, anchorId) => {
    await getObjectivesByProjectIdAndAnchorId(projectId, anchorId, setError, setObjectivesByProjectAndAnchorData);
  };

  const handleDelete = async () => {
    // Fetch objectives
    await fxAPIFetchObjectivesByProjectAndAnchorId(props.relatedProject, props.anchorId);

    // Check results and log accordingly
    if (objectivesByProjectAndAnchor.length > 0) {
      console.log(
        `Warning: Cannot delete anchor ID ${props.anchorId} for project ${props.relatedProject} as it has associated objectives.`
      );
    } else {
      console.log(
        `Approve: Anchor ID ${props.anchorId} for project ${props.relatedProject} can be safely deleted.`
      );
    }
  };

  return (
    <div className="fx-comp-anchor-toolbar-outer">
      <EditIcon />
      <CompUIAssetAnchorModal anchorId={props.anchorId} />
      <div onClick={handleDelete}>
        <DeleteIcon />
      </div>
    </div>
  );
};

export default CompAnchorToolbar;