import React, { useEffect, useState } from 'react';
import './PageAuthCallback.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import axios from 'axios';

export default function PageAuthCallback() {
  

  return (
    <CompPageTempMaster3Panel headingOne="Welcome, user...">
      Callback...
    </CompPageTempMaster3Panel>
  );
}