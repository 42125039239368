import React, { useEffect, useState } from 'react';
import './CompCRUDFormTeamMember.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createUser, updateUser } from '../fxAPI/fxUsersAPI.js';

const CompCRUDFormTeamMember = (props) => {

  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState(null);
  const [userResponse, setUserResponse] = useState(null);

  console.log('current team member data on form team member: ');
  console.dir(props.currentComponentData);

  const handleCreateUser = (event) => {
    event.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    createUser(username, emailAddress, relatedOrg, setError, setUserResponse);
  };

  const [currentEditableState, setCurrentEditableState] = useState('view');

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleUpdateUser = async (e) => {
    console.log('ran update user: ' + props.currentComponentData.user_id);
    e.preventDefault();
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const userId = props.currentComponentData.user_id;
    const username = 'UpdatedUserFromAPI';
    const emailAddress = 'Updated@fromAPI.com';
    const related_org = '12345';
  
    await updateUser(userId, username, emailAddress, related_org);
  };

  return (
    <div>
        {props.crudMethod === "create" &&
          <div>
          <form onSubmit={handleCreateUser}>
          <br />
            <div>
              <TextField id="outlined-basic" label="Username" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <br />
            <div>
              <TextField id="outlined-basic" label="Email Address" variant="outlined" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} /> 
            </div>
            
            <CompModalButtons>
                  <Button type="submit">Create User</Button>
                </CompModalButtons>  
          </form>

          {error && <div style={{ color: 'red' }}>{error}</div>}
          {userResponse && (
            <div>
              <h3>User Created Successfully:</h3>
              <pre>{JSON.stringify(userResponse, null, 2)}</pre>
            </div>
          )}
          </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>First Name</div>
                <div>{props.currentComponentData.first_name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Last Name</div>
                <div>{props.currentComponentData.last_name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Email</div>
                <div>{props.currentComponentData.email_address}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Role</div>
                <div>{props.currentComponentData.role}</div>
              </div>
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateUser}>
                <div>
                  <TextField id="outlined-basic" label="First Name" variant="outlined" />
                </div>   
                <br />
                <div>
                  <TextField id="outlined-basic" label="Last Name" variant="outlined" />
                </div> 
                <br />
                <div>
                  <TextField id="outlined-basic" label="Email" variant="outlined" />
                </div> 
                <CompModalButtons>
                  <Button type="submit">Save Team Member</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormTeamMember;