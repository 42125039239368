import React, { useState, useEffect, useContext } from 'react';
import './CompObjectiveAnchorCard.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompSubjectivePanel from './CompSubjectivePanel';


const CompObjectiveAnchorCard = (props) => {

  {/* 
  const [refresh, setRefresh] = useState(false);

  const handleRefreshAction = () => {
    // Perform some action in the parent
    // After the action, toggle the refresh state
    setRefresh(prev => !prev);
  };

  */}

  return (
    <div>
      <CompSubjectivePanel 
                //refreshParent={props.refreshParent} 
                panelName="Subjectives" 
                relatedObjective={props.relatedObjective}
                relatedProject={props.relatedProject}
                //relatedObjective={item.objective_id} 
                //relatedProject={props.relatedProject} 
                //refresh={refresh}  // Use refresh to trigger panel update
                //updatePanelContext={(contextType, objectId, object) => props.updatePanelContext(contextType, objectId, object)}
              />

    </div>
  );
};

export default CompObjectiveAnchorCard;