import React, { useState, useEffect } from 'react';
import './CompDashCard.css';
import Button from '@mui/material/Button';

const CompDashCard = (props) => {
  const [error, setError] = useState(null);

  useEffect(() => {

  }, []);

  return (
    <div className="fx-comp-dash-card-container">
      <h3>{props.cardTitle}</h3>
      {props.children}
    </div>
  );
};

export default CompDashCard;