import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './CompCRUDFormObjective.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createObjective, updateObjective } from '../fxAPI/fxObjectivesAPI.js';
import Slider from '@mui/material/Slider';

const CompCRUDFormObjective = (props) => {

  const params = useParams();

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [updateObjectiveName, setUpdateObjectiveName] = useState(props.currentComponentData.objective_view_name);
  const [updateObjectiveDescription, setUpdateObjectiveDescription] = useState(props.currentComponentData.objective_view_description);


  console.log('current objective data on form objective: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
    if(currentViewMode === "edit") {
      console.log('triggered edit objective');
    }
  };

  const handleUpdateObjectiveNameChange = (e) => {
    setUpdateObjectiveName(e.target.value);
  }

  const handleUpdateObjectiveDescriptionChange = (e) => {
    setUpdateObjectiveDescription(e.target.value);
  }

  const handleCreateObjective = async (event) => {
    event.preventDefault();
    if(params.type === "projects") {
      var relatedProject = params.compId;
    } else {
      var relatedProject = '';
    }
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const progress = '0';

    await createObjective(name, description, relatedOrg, relatedProject, progress);
  };

  const handleUpdateObjective = async (e) => {
    console.log('ran update objective: ' + props.currentComponentData.objective_id);
    e.preventDefault();
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const objectiveId = props.currentComponentData.objective_id;
    const name = updateObjectiveName;
    const description = updateObjectiveDescription;
    const related_org = currentOrgId;
  
    await updateObjective(objectiveId, name, description, related_org, null);
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateObjective}>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Objective Name"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Objective Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Create Objective</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              {/*<div>Test: {props.currentComponentData.objective_id}</div>*/}
              <div>
                <div className='fx-global-form-read-only-label'>Objective Name</div>
                <div>{props.currentComponentData.objective_view_name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Objective Description</div>
                <div>{props.currentComponentData.objective_view_description}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Progress</div>
                <div className='fx-objective-detail-slider-wrap'><Slider defaultValue={props.currentComponentData.progress} aria-label="Default" valueLabelDisplay="auto" /></div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Project</div>
                <div>{props.currentComponentData.project_view_name}</div>
              </div>
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateObjective} className="fx-global-crud-edit-form">
                <div>
                  <TextField id="outlined-basic" label="Objective Name" variant="outlined" value={updateObjectiveName} onChange={handleUpdateObjectiveNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Objective Description"
                  value={updateObjectiveDescription}
                  onChange={handleUpdateObjectiveDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type="submit">Save Objective</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormObjective;