import React, { useEffect, useState } from 'react';
import './CompListViewActionBar.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ViewListIcon from '@mui/icons-material/ViewList';
import TableViewIcon from '@mui/icons-material/TableView';
import GridViewIcon from '@mui/icons-material/GridView';
import CompCRUDModal from './CompCRUDModal';

const CompListViewActionBar = ({ onViewChange, someOtherProp, actionModalTitle, itemType, crudMethod }) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleViewChange = (viewState) => {
        onViewChange(viewState);
      };

      const openModal = () => {
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };

  return (
    <div>
      <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={actionModalTitle} itemType={itemType} crudMethod={crudMethod}></CompCRUDModal>

      <div className="fx-comp-list-view-action-bar-container">
            <Button variant='outlined' onClick={openModal}>Create</Button>
            <Button onClick={() => handleViewChange('list')}><ViewListIcon /></Button>
            <Button onClick={() => handleViewChange('grid')}><GridViewIcon /></Button>
            <Button onClick={() => handleViewChange('table')}><TableViewIcon /></Button>
        </div>
    </div>
  );
};

export default CompListViewActionBar;